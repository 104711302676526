import axios from "axios";
import {
  ADDBROCHER_TEMPLATE_DESIGN,
  ADD_ANNOUCEMENT,
  ADD_SUPPORT,
  ADD_AREA,
  ADD_BROCHURE_FOLDER,
  ADD_CITY,
  ADD_CLOSING,
  ADD_CONTACT,
  ADD_CONTACT_REPORT,
  ADD_COUNTRY,
  ADD_CREATEMEETING,
  ADD_DIRECT_MAILER_TEMPLATE,
  ADD_EMAIL_TEMPFOLDER,
  ADD_EMAIL_TEMPLATE,
  ADD_EVENT,
  ADD_LISTING,
  ADD_MAILER_TEMPLATE,
  ADD_NOTE,
  ADD_POST_MAIL_UPDATED,
  ADD_PROPERTY,
  ADD_SENDEMAIL_TEMPLATE,
  ADD_SENDSMS_TEMPLATE,
  ADD_SEQUENCE,
  ADD_SEQUENCE_SETTING,
  ADD_SEQUENCE_SHARE,
  ADD_SERVICES,
  ADD_SMS_TEMPFOLDER,
  ADD_SMS_TEMPLATE,
  ADD_STATE,
  ADD_SUBAREA,
  ADD_SUGGEST,
  ADD_TAG,
  ADD_TASK,
  ADD_TASK_DROPDOWN,
  ALL_CITY,
  ALL_CONTACT,
  ALL_COUNTRY,
  ALL_LOCALITIES,
  ALL_SERVICES,
  ALL_STATE,
  ALL_TASK,
  ALL_TRANSACTION,
  ALL_USERS,
  AUTO_SUGGESTCITYAPI,
  AUTO_SUGGESTCOUNTRY,
  AUTO_SUGGESTSTATE,
  All_SUGGEST_PROPERTIES,
  CHOOSE_CONTACT,
  COMMON_ADD_USER_DROPDOWNS,
  COMMON_DROPDOWN_OPTIONS,
  CONTACTPREVIEW_ADDPROPERTY,
  CONTACT_DELETE_PROPERTY,
  CONTACT_FOR_DROPDOWN,
  CONTACT_USERFOR_DROPDOWN,
  COUNTRY_UPDATE,
  DELETE_EMAIL,
  DELETE_EMAIL_TEMPLATE,
  DELETE_NOTE,
  DELETE_SMS_TEMPLATES,
  EDIT_ADDCLOSING,
  EDIT_CONTACT,
  EDIT_EMAILTEMPLATE,
  EDIT_LISTING,
  EDIT_NOTE,
  EDIT_PROPERTY,
  EDIT_SMS_TEMPLATE,
  EDIT_TRANSACTION_NOTE,
  EVENT_DELETE,
  FORGOT_PASSWORD,
  GETBROCHER_TEMPLATE_DESIGN,
  GETEVENT_BY_USERID,
  GETLISTING_MLSAPI,
  GETMETA_DATA_MLS,
  GET_ALLANNOUCEMNET,
  GET_ALLCOUNTRIES,
  GET_ALLSTATEDATA,
  GET_ALLTAGS,
  GET_ALL_AREA,
  GET_ALL_EVENTS,
  GET_ALL_SUBAREA,
  GET_ASSIGNED_DROPDOWN,
  GET_AllINTERCTION_CONTACTPREVIEW,
  GET_BROCHURE_FOLDER,
  GET_CITY_AUTO,
  GET_CLOSINGVIEWDATA,
  GET_CLOSING_TRANSACTION,
  GET_CONTACT_PREVIEW,
  GET_CONTACT_PRICERANGE,
  GET_COUNT_BY_WEEK,
  GET_DIRECT_MAILER_TEMPLATE,
  GET_EMAIL_TEMPFOLDER,
  GET_EMAIL_TEMPLATE,
  GET_EVENTBYID,
  GET_GOAL_COUNT,
  GET_HOME_COMPAREDTO,
  GET_MAILER_TEMPLATE,
  GET_MARKETING_DROPDOWN,
  GET_NOTIFICATION,
  GET_PARTICIPENTS,
  GET_PERTICULARSEQUANCE,
  GET_PERTICULAR_SEQUANCE_TASK,
  GET_PROPERTYBYID,
  GET_SEQUENCE,
  GET_SMS_TEMPFOLDER,
  GET_SMS_TEMPLATE,
  GET_SUGGESTEDUSERS,
  GET_TAGS,
  GET_TASK_DAY,
  GET_TASK_WEEK,
  GET_TRANSACTION_DROPDOWNS,
  GET_USER_ADMINTYPE,
  GEt_ALLCITYDATA,
  GOALFOR_COMPARISION,
  HOME_PAGE_CONTACT,
  INVITE_USER,
  LOGIN,
  LOG_TASK,
  OUT_CREATE_EVENT,
  OUT_LOOK_AUTH,
  OUT_UPDATE_EVENT,
  PARCTICULAR_PROPERTYBYID,
  REFRESH_TOKEN,
  REPORTS_ADD_GOAL,
  RESET_PASSWORD,
  RESET_PASSWORD_LOGIN,
  SEQUANCE_SETTING_UPDATE,
  SEQUANCE_STATUS_UPDATE,
  SEQUANCE_UPDATE,
  SERVICESTATUSUPDATE,
  SUGGEST_LOCALITIES,
  TASK_AND_HOME,
  TASK_DELETE,
  TRANSACTION_ADD_DOCUMENT,
  TRANSACTION_ADD_NOTE,
  TRANSACTION_CLOSING,
  TRANSACTION_DELETE_IMAGE,
  TRANSACTION_DELETE_NOTES,
  TRANSACTION_PENDING_CHANGE_STATUS,
  TRANSACTION_VIEW,
  TWO_STEP_UPDATE,
  UPDATE_ANNOUCEMENT,
  UPDATE_AREA,
  UPDATE_CITYSTATUS,
  UPDATE_EVENT,
  UPDATE_STATE,
  UPDATE_SUBAREA,
  UPDATE_TAG,
  UPDATE_TASK,
  UPDATE_TASK_ALL_FIELD,
  UPDATE_TASK_STATUS,
  USERS_EDIT,
  USERS_UPDATE_STATUS,
  USER_DETAILS,
  USER_FOR_DROPDOWN,
  GET_PROPERTY,
  transactionActiveStatusChange,
  DELETE_DIRECTMAILERTEMPLATE_TEMPLATE,
  DELETE_BROCHURE_TEMPLATE,
  OTPLOGIN,
  Get_BRACKETREPLACE_FROM_BACKEND,
  IMPORT_CONTACT_API,
  RENAME_DIRECTMAILER_TEMPLATE_FOLDERNAME,
  RENAME_BROCHURE_TEMPLATE_FOLDERNAME,
  RENAME_EMAIL_TEMPLATE_FOLDERNAME,
  RENAME_SMS_TEMPLATE_FOLDERNAME,
  HANDLE_NOTIFICATIONS,
  ALL_CITIES,
  UPDATE_CMA,
  UPDATE_DIRECT_MAILER_TEMPLATE,
  ACTIVE_ANNOUNCEMENT_TODAY,
  REPORTS_LOCK_UNLOCK,
  GET_MYREPORT_TASK_PERFORMANCEDATA,
  ADD_REGION,
  GEt_ALLREGIONDATA,
  UPDATE_REGIONSTATUS,
  ADD_BROCHURE_DOCUEMEN_FORMAT,
  ALL_TIMEZONES_OPTIONS,
  OUTLOOK_LOGIN,
  GET_MYREPORT_TASK_PERFORMANCEDATAYEARWISE,
  ADD_EVENT_NOTIFICATION,
  DELETE_EMAIL_TEMPLATE_FOLDERNAME,
  DELETE_DIRECTMAILER_TEMPLATE_FOLDERNAME,
  DELETE_BROCHURE_TEMPLATE_FOLDERNAME,
  DELETE_SMS_TEMPLATE_FOLDERNAME,
  PRIMARY_SELLER_DATA,
  AUTO_SUGGESTREGION,
  ALL_LOCALITIES2ND,
  SEND_INVITE_TO_CONTACT,
  LOG_TASK_EXTERNAL,
  SAVE_SIGNATURE,
  ADD_LISTING_AS_DRAFT,
  GET_SIGNATURE,
  GET_ALL_DRAFT,
  MARK_NOTIFICATION_2ND,
  GET_NOTIFICATION_READ,
  DELETE_SIGNATURE,
  TRANSACTION_VIEW_DRAFT,
  EDIT_LISTING_DRAFT_SECTION,
  MIGRATE_DATA_API,
  GET_TEAM_REPORT_DATA,
  SEARCH_ALLSTATEDATA,
  SEARCH_ALLCITYDATA,
  SEARCH_ALL_AREA,
  SEARCH_ALL_SUBAREA,
  SEARCH_ALLREGIONDATA,
  ACCEPT_ANNOUCEMENT,
  RELATION_FOR_DROPDOWN,
  CONTACT_PROPERTY_FOR_DROPDOWN,
  CONFIRM_CMA_NOTIFICATION,
  UPDATE_ANNOUCEMENT_DATA,
  VIEWED_ANNOUNCEMENT_TODAY,
  ALL_LOCALITIES_FORMaster,
  DELETE_ANNOUNCEMENT_IMAGE,
  GET_ANNOUNCEMENTBY_ID,
  HOME_PAGE_CONTACT_DATA,
  TRANSACTION_CONFIRMATION,
  ADD_LISTING_PENDING,
  MLS_API_URL,
  MLS_DATA_API_ROUTE_NEW,
  SEND_COMPOSE_API,
  GET_EXPORT_USER_DATA,
  ADD_TEAM_LEADER_DATA,
  UPDATE_TEAM_LEADER_DATA,
  ALL_TEAM,
  TEAMS_FOR_DROPDOWN,
  EXPORT_TEAM_EXCEL,
  SEND_USER_CREDENTIAL_ROUTE,
  ALL_TEAM_SEARCH,
  CONTACT_PREVIEW_DOCUMENT_DELETE,
  ALL_TAG_SEARCH,
  ALL_SERVICE_SEARCH,
  ALL_ANNOUNCEMENT_SEARCH,
  IMPORT_CONTACT_API_SECONDARY,
  GET_MY_REPORT,
  UPDATE_SERVICE,
  GET_PERTICULAR_SERVICE,
  GET_PERTICULAR_TAG,
  UPDATE_TAG_ROUTE,
  ALL_STATE_DATA,
  GET_STATE_BY_ID,
  UPDATE_STATE_API,
  INACTIVECONTACT_API,
  DELETE_REATION_CONTACT,
  ACTIVE_LISTING_EVENT_PROPERTY,
  DELETE_CONTACT_PROPERTY,
  GET_SEQUENCE_DETAILS_BY_ID,
  UPDATE_SEQUENCE_CONTACT,
  AUTOCOMPLETE_TAGS_API,
  SEQUENCE_START_API,
  SEQUENCE_ANALYZE_API,
  SEQUENCE_ANALYZE_DETAILS_API,
  SEQUENCE_ANALYZE_HEIGHLIGHTED_API,
  POST_IMAGE_URL_CDN,
} from "./Route";
import Axios from "../Function/Axios";
import { Bounce, toast } from "react-toastify";
import { removeToken } from "../Function/auth";

export const Base_Url = process.env.REACT_APP_BASE_URL; //test server url
// export const Base_Url = "http://3.96.43.128/api/";  //preduction url  https://adminapi.virani.ca/
// export const Base_Url = "https://adminapi.virani.ca/api/";

axios.defaults.baseURL = Base_Url;
Axios.defaults.baseURL = Base_Url;
// AxiosMls.defaults.baseURL = "https://reb.retsiq.com/contactres/rets/"

const AxiosMls = axios.create({
  baseURL: "https://reb.retsiq.com/contactres/rets/",
});

export const getMetaDataMlsApi = async () => {
  try {
    const response = await AxiosMls.get(
      GETMETA_DATA_MLS,
      {},
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getExportuserApi = async () => {
  try {
    const response = await Axios.get(
      GET_EXPORT_USER_DATA,
      { responseType: "blob" },
      {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    if (response.status === 200) {
      return { res: response };
    } else return response;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const Mls_data_api = async (data) => {
  try {
    const response = await Axios.get(
      MLS_DATA_API_ROUTE_NEW,
      {
        params: {
          L_DisplayID: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const SaveSignatureFunction = async (data) => {
  try {
    const response = await Axios.post(SAVE_SIGNATURE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DeletedSignatureFunction = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_SIGNATURE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const OutLookloginService = async (data) => {
  try {
    const response = await Axios.post(OUTLOOK_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const MlsHandlingdata = async (data) => {
  try {
    const response = await axios.post(MLS_API_URL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const Server500Handler = () => {
  // const dispatch = useDispatch();
  // // useEffect(() => {
  //   dispatch(AuthAction.ServerRespondedReducer());
  // // }, [dispatch]);
  // // Adjust the JSX based on what you want to render or return null
  // return <div>Hello World!</div>;
};

// export const Dispatchler = () => {
//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(handleISalltaskcompleted(true));
//   });

//   return <hello></hello>;
// };

export const forgotPasswordApi = async (data) => {
  try {
    const response = await axios.post(FORGOT_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpApi = async (data) => {
  try {
    const response = await axios.post(OTPLOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const resetPasswordApiLogin = async (data) => {
  try {
    const response = await axios.post(RESET_PASSWORD_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllUsersApi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_USERS,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
          admintype: data?.admintype,
          userStatus: data?.userStatus,
          lastlogintime: data?.lastlogintime,
          searchvalue: data?.searchvalue,
        },
      },

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getTeamDataApi = async (data) => {
  try {
    let response;

    if (!data?.name || data?.name?.length === 0) {
      response = await Axios.get(
        ALL_TEAM,
        {
          params: {
            Page: data?.Page,
            ItemsPerPage: data?.ItemsPerPage,
            admintype: data?.admintype,
            userStatus: data?.userStatus,
            lastlogintime: data?.lastlogintime,
            name: data?.name,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      response = await Axios.get(
        ALL_TEAM_SEARCH,
        {
          params: {
            // Page: data?.Page,
            // ItemsPerPage: data?.ItemsPerPage,
            // admintype: data?.admintype,
            // userStatus: data?.userStatus,
            // lastlogintime: data?.lastlogintime,
            name: data?.name,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    }

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// const response = await Axios.post(IMPORT_CONTACT_API, data, {

export const inviteUserApi = async (data) => {
  try {
    const response = await Axios.post(INVITE_USER, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const refreshTokenApi = async (data) => {
  try {
    const response = await Axios.post(REFRESH_TOKEN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const resetPasswordApi = async (data) => {
  try {
    const response = await Axios.post(RESET_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCountryApi = async () => {
  try {
    const response = await Axios.get(ALL_COUNTRY, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStateApi = async (countryId) => {
  try {
    const response = await Axios.get(
      ALL_STATE,
      {
        params: {
          countryId: countryId,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getDynamicEmailSignature = async (data) => {
  try {
    const response = await Axios.get(
      GET_SIGNATURE,
      {
        params: {
          UserId: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getEventByUserIdApi = async (userId) => {
  try {
    const response = await Axios.get(
      GETEVENT_BY_USERID,
      {
        params: {
          userId: userId?.userId,
          // month: userId?.month ?? 2,
          startDate: userId?.month?.startDate,
          endDate: userId?.month?.endDate,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
 
    if (err?.response?.data?.statusCode === 400) {
      toast.info(err?.response?.data?.errors?.[0]?.description);

      const result = await OutLookloginService();

      if (result?.res) {
        setTimeout(() => {
          window.location.replace(result.res);
        }, 5000);
        throw "microsoftauthenitcationfailed";
      }
    }

    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCityApi = async (stateId) => {
  try {
    const response = await Axios.get(
      ALL_CITY,
      {
        params: {
          stateId: stateId,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCommonDropDownOptionsApi = async (stateId) => {
  try {
    const response = await Axios.get(COMMON_DROPDOWN_OPTIONS, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllDraft = async (stateId) => {
  try {
    const response = await Axios.get(GET_ALL_DRAFT, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllTimeZonesDropDownOptionsApi = async (stateId) => {
  try {
    const response = await Axios.get(ALL_TIMEZONES_OPTIONS, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllContactAPi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_CONTACT,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
          primary_contact_owner: data?.primary_contact_owner,
          rating: data?.rating,
          duration: data?.duration,
          value: data?.value,
          tagId: data?.tagId,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPartcularTaskAPi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_TASK,
      {
        params: {
          taskId: data,
          Page: 1,
          ItemsPerPage: 10,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSequenceApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_SEQUENCE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSequenceAnalyzeApi = async (DATA = 1) => {
  try {
    const apiUrl =
      DATA === 1
        ? SEQUENCE_ANALYZE_API
        : (DATA = 2
            ? SEQUENCE_ANALYZE_DETAILS_API
            : SEQUENCE_ANALYZE_HEIGHLIGHTED_API);

    const response = await Axios.get(
      apiUrl,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSequenceAnalyzeHeighlishgedApi = async (DATA = 1) => {
  try {
    const apiUrl = SEQUENCE_ANALYZE_HEIGHLIGHTED_API;

    const response = await Axios.get(
      apiUrl,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPartcularEventAPi = async (data) => {
  try {
    const response = await Axios.get(
      GET_EVENTBYID,
      {
        params: {
          eventID: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPartcularPropertyAPi = async (data) => {
  try {
    const response = await Axios.get(
      GET_PROPERTYBYID,
      {
        params: {
          propertyId: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllTaskAPi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_TASK,
      {
        params: {
          tasktypeId: data?.tasktypeId,
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
          assignedId: data?.assignedId,
          status: data?.status,
          date: data?.date,
          agentId: data?.agentId,
          duration: data?.duration,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const Addday = async ({ date = new Date(), addition }) => {
  const originalDate = new Date(date);

  // Add the specified number of days
  originalDate.setDate(originalDate.getDate() + Number(addition));

  const originalDateStr = originalDate.toISOString();

  // Split the string to separate date and time components
  const [datePart, timePart] = originalDateStr.split("T");

  return `${datePart}T${timePart}`;
};

export const AdddayWith2Minute = async () => {
  const originalDate = new Date();

  // Add the specified number of minutes (2 minutes in this case)
  originalDate.setMinutes(originalDate.getMinutes() + Number(2));

  const originalDateStr = originalDate.toISOString();

  // Split the string to separate date and time components
  const [datePart, timePart] = originalDateStr.split("T");

  return `${datePart}T${timePart}`;
};

export const addContactApi = async (data) => {
  try {
    const response = await Axios.post(ADD_CONTACT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const sendInviteToContact = async (data) => {
  try {
    const response = await Axios.post(`${SEND_INVITE_TO_CONTACT}?id=${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editContactApi = async (data) => {
  try {
    const response = await Axios.put(EDIT_CONTACT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const UpdateCMA = async (data) => {
  try {
    const response = await Axios.post(UPDATE_CMA, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const MigrateDataApi = async (data) => {
  try {
    const response = await Axios.put(MIGRATE_DATA_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ImportContactApi = async (data) => {
  try {
    const response = await Axios.post(IMPORT_CONTACT_API, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      // toast.success("contact imported successfully");
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ImportContactSecondaryApi = async (data) => {
  try {
    const response = await Axios.post(IMPORT_CONTACT_API_SECONDARY, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      // toast.success("contact imported successfully");
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ExportTeamExcelApi = async (data) => {
  try {
    const response = await Axios.get(
      EXPORT_TEAM_EXCEL,
      { responseType: "blob" },
      {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    if (response.status === 200) {
      // toast.success("contact imported successfully");
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addTaskApi = async (data) => {
  try {
    const response = await Axios.post(ADD_TASK, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updateTaskApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_TASK, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updateTaskAllFieldApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_TASK_ALL_FIELD, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllLocalitiesApi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_LOCALITIES,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllLocalitiesApiForMaster = async (data) => {
  try {
    const response = await Axios.get(
      ALL_LOCALITIES_FORMaster,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllLocalitiesApi2nd = async (data) => {
  try {
    const response = await Axios.get(
      ALL_LOCALITIES2ND,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPrimarySellerdTAApi = async (data) => {
  try {
    const response = await Axios.get(
      PRIMARY_SELLER_DATA,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllCitiesApi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_CITIES,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllStateDataApi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_STATE_DATA,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAutoCompleteTagsDataApi = async (data) => {
  try {
    const response = await Axios.get(
      AUTOCOMPLETE_TAGS_API,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMlsIdData = async (data) => {
  try {
    const response = await axios.get(
      "https://reb.retsiq.com/contactres/rets/search?",
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSuggestlocaltiesApi = async (data) => {
  try {
    const response = await Axios.get(
      SUGGEST_LOCALITIES,
      {
        params: {
          stateid: data?.stateid,
          cityid: data?.cityid,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSuggestedUserApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_SUGGESTEDUSERS,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getUserFroDropDownApi = async (data = false) => {
  try {
    const response = await Axios.get(
      USER_FOR_DROPDOWN,
      {
        params: {
          teamRequired: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getTeamsFroDropDownApi = async (data = false) => {
  try {
    const response = await Axios.get(
      TEAMS_FOR_DROPDOWN,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getrelationFroDropDownApi = async (data) => {
  try {
    const response = await Axios.get(RELATION_FOR_DROPDOWN, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllEventApi = async (data) => {
  try {
    const response = await Axios.get(GET_ALL_EVENTS, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllGoalApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_GOAL_COUNT,
      {
        params: {
          year: data?.year,
          month: data?.month,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMyReportPrintedData = async (data) => {
  try {
    const response = await Axios.get(GET_MY_REPORT, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getTeamReportData = async (data) => {
  try {
    const response = await Axios.get(
      GET_TEAM_REPORT_DATA,
      {
        params: {
          year: data?.year,
          month: data?.month,
          teamMembers: data?.teamMembers?.join(","),
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllReportTaskPerformanceData = async (data) => {
  try {
    const response = await Axios.get(
      GET_MYREPORT_TASK_PERFORMANCEDATA,
      {
        params: {
          year: data?.year,
          month: data?.month,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllReportTaskPerformanceDataYearWise = async (data) => {
  try {
    const response = await Axios.get(
      GET_MYREPORT_TASK_PERFORMANCEDATAYEARWISE,
      {
        params: {
          year: data?.year,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCityAutoApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_CITY_AUTO,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userStateUpdate = async (id) => {
  try {
    const response = await Axios.put(
      USERS_UPDATE_STATUS,
      {
        id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const sendUserCredentialApi = async (data) => {
  try {
    const response = await Axios.put(
      SEND_USER_CREDENTIAL_ROUTE,
      {
        userId: data?.userId,
        passwordsend: data?.passwordsend,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const serviceStateUpdate = async (id) => {
  try {
    const response = await Axios.put(
      SERVICESTATUSUPDATE,
      {
        id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const userEditApi = async (data) => {
  try {
    const response = await Axios.put(`${USERS_EDIT}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const transactionConfirmation = async (data) => {
  try {
    const response = await Axios.put(`${TRANSACTION_CONFIRMATION}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getContactPreview = async (ContactId) => {
  try {
    const response = await Axios.get(
      `${GET_CONTACT_PREVIEW}`,
      {
        params: {
          ContactId: ContactId,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTagsApi = async () => {
  try {
    const response = await Axios.get(`${GET_TAGS}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addGoalApi = async (data) => {
  try {
    const response = await Axios.post(`${REPORTS_ADD_GOAL}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addTeamLeaderApi = async (data) => {
  try {
    const response = await Axios.post(`${ADD_TEAM_LEADER_DATA}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const updateTeamLeaderApi = async (data) => {
  try {
    const response = await Axios.put(`${UPDATE_TEAM_LEADER_DATA}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GoalLockUnlock = async (data) => {
  try {
    const response = await Axios.put(
      `${REPORTS_LOCK_UNLOCK}`,
      {
        id: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getContactForDropDownsApi = async () => {
  try {
    const response = await Axios.get(`${CONTACT_FOR_DROPDOWN}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addEventApi = async (data) => {
  try {
    const response = await Axios.post(`${ADD_EVENT}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addEventApiNotification = async (data) => {
  try {
    const response = await Axios.post(
      `${ADD_EVENT_NOTIFICATION}?userId=${data?.userId}&notificationType=${data?.notificationType}&message=${data?.message}`,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTaskByWeekApi = async (data) => {
  try {
    const response = await Axios.get(
      `${GET_TASK_WEEK}`,
      {
        params: {
          status: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTaskByDayApi = async (data) => {
  try {
    const response = await Axios.get(
      `${GET_TASK_DAY}`,
      {
        params: {
          status: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getContactPriceRange = async () => {
  try {
    const response = await Axios.get(`${GET_CONTACT_PRICERANGE}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addTaskDropDownApi = async () => {
  try {
    const response = await Axios.get(`${ADD_TASK_DROPDOWN}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTransactionDropDownsApi = async () => {
  try {
    const response = await Axios.get(`${GET_TRANSACTION_DROPDOWNS}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTransactionClosingApi = async (data) => {
  try {
    const response = await Axios.get(`${GET_CLOSING_TRANSACTION}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addPostMailApi = async (data) => {
  try {
    //ADD_POST_MAIL_UPDATED ADD_POST_MAIL
    const response = await Axios.post(`${ADD_POST_MAIL_UPDATED}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getHomePageContactApi = async () => {
  try {
    const response = await Axios.get(`${HOME_PAGE_CONTACT}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAddCommonUserDropdownsApi = async () => {
  try {
    const response = await Axios.get(`${COMMON_ADD_USER_DROPDOWNS}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getHomePageCOntactdataApi = async (data) => {
  try {
    const response = await Axios.get(
      `${HOME_PAGE_CONTACT_DATA}`,
      {
        params: {
          Name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSupportDropdownsApi = async () => {
  try {
    const response = await Axios.get(`${COMMON_ADD_USER_DROPDOWNS}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const updateTaskStatusApi = async (data) => {
  try {
    const response = await Axios.put(
      UPDATE_TASK_STATUS,
      {
        id: data?.id,
        status: data?.status === "InComplete" ? 1 : 0,
      },

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addlisting = async (data) => {
  try {
    const response = await Axios.post(ADD_LISTING, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addlistingPending = async (data) => {
  try {
    const response = await Axios.post(ADD_LISTING_PENDING, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addlistingasDraft = async (data) => {
  try {
    const response = await Axios.post(ADD_LISTING_AS_DRAFT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addContactReportApi = async (data) => {
  try {
    const response = await Axios.post(ADD_CONTACT_REPORT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addBrochureDocuementFormatApi = async (data) => {
  try {
    const response = await Axios.post(ADD_BROCHURE_DOCUEMEN_FORMAT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addSuggestShareApi = async (data) => {
  try {
    const response = await Axios.post(ADD_SUGGEST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editlisting = async (data) => {
  try {
    const response = await Axios.put(EDIT_LISTING, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editlistingDraftSection = async (data) => {
  try {
    const response = await Axios.put(EDIT_LISTING_DRAFT_SECTION, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updateEventApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_EVENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSequenceDetaislBySequenceId = async (data) => {
  try {
    const response = await Axios.get(
      GET_SEQUENCE_DETAILS_BY_ID,
      {
        params: {
          sequenceid: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const updateSequencedetails = async (data) => {
  try {
    const response = await Axios.put(
      UPDATE_SEQUENCE_CONTACT,
      {
        id: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTransactionApi = async (data) => {
  try {
    const response = await Axios.get(
      ALL_TRANSACTION,
      {
        params: {
          AgentId: data?.AgentId,
          // ExpiryDate: data?.ExpiryDate,
          sort: data?.sort,
          ListedDate: data?.ListedDate,
          Status: data?.Status,
          monthsRange: data?.monthsRange,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getListingMLSApi = async (data) => {
  try {
    const response = await Axios.get(GETLISTING_MLSAPI, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTransactionClosingDropdownApi = async () => {
  try {
    const response = await Axios.get(TRANSACTION_CLOSING, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getComparedToHomeApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_HOME_COMPAREDTO,
      {
        params: {
          value: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addClosingTransaction = async (data) => {
  try {
    const response = await Axios.post(ADD_CLOSING, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getTransactionViewApi = async (data) => {
  try {
    const response = await Axios.get(
      `${TRANSACTION_VIEW}`,
      {
        params: {
          listingid: data?.listingid,
          liststatus: data?.liststatus,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getTransactionViewApiDraft = async (data) => {
  try {
    const response = await Axios.get(
      `${TRANSACTION_VIEW_DRAFT}`,
      {
        params: {
          listingid: data?.listingid,
          liststatus: data?.liststatus,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCountByMonthTaskpi = async (data) => {
  try {
    const response = await Axios.get(`${TASK_AND_HOME}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCountBySevenTaskpi = async (data) => {
  try {
    const response = await Axios.get(`${GET_COUNT_BY_WEEK}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllinterctionContactPreviewApi = async (id) => {
  try {
    const response = await Axios.get(
      `${GET_AllINTERCTION_CONTACTPREVIEW}`,
      {
        params: { id: id },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getMeetingContactPreviewApi = async (data) => {
  try {
    const response = await Axios.get(
      `${GET_AllINTERCTION_CONTACTPREVIEW}`,
      {
        params: { id: data.id, flag: data.slug },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getContactPreviewPropertyApi = async (data) => {
  try {
    const response = await Axios.get(
      `${CONTACTPREVIEW_ADDPROPERTY}`,
      {
        params: { id: data.id, flag: data.slug },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addLogTaskApi = async (data) => {
  try {
    const response = await Axios.post(`${LOG_TASK}`, data, {
      params: { logType: data.logType },
      headers: { "Content-Type": "application/json" },
    });
    // if (response?.data?._response?.message === "Completed") {
    //   Dispatchler();
    // }

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addLogTaskApiExternal = async (data) => {
  try {
    const response = await Axios.post(`${LOG_TASK_EXTERNAL}`, data, {
      params: { logType: data.logType },
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addBrocherTemplateApi = async (data) => {
  try {
    const response = await Axios.post(`${ADDBROCHER_TEMPLATE_DESIGN}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getBrocherTemplateApi = async (data) => {
  try {
    const response = await Axios.get(
      `${GETBROCHER_TEMPLATE_DESIGN}`,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getUerDetailsApi = async (id) => {
  try {
    const response = await Axios.get(
      `${USER_DETAILS}`,
      {
        params: { Userid: id },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPreviewAddNote = async (data) => {
  try {
    const response = await Axios.post(ADD_NOTE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPreviewEditNote = async (data) => {
  try {
    const response = await Axios.put(EDIT_NOTE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TransactionStatusUpdateNote = async (data) => {
  try {
    const response = await Axios.put(transactionActiveStatusChange, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TransactionStatusPendingApi = async (data) => {
  try {
    const response = await Axios.put(TRANSACTION_PENDING_CHANGE_STATUS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPreviewDeleteNote = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_NOTE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeleteRelationContact = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_REATION_CONTACT,
      {
        params: {
          Id: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DeleteContactProperty = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_CONTACT_PROPERTY,
      {
        params: {
          Id: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPropertyDelete = async (data) => {
  try {
    const response = await Axios.delete(
      CONTACT_DELETE_PROPERTY,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPreviewCresteMeeting = async (data) => {
  try {
    const response = await Axios.post(ADD_CREATEMEETING, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const taskDeleteApi = async (data) => {
  try {
    const response = await Axios.delete(
      TASK_DELETE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteEmailAPi = async (id) => {
  try {
    const response = await Axios.delete(
      DELETE_EMAIL,
      {
        params: id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const sendComposeMessage = async (data) => {
  try {
    const response = await Axios.post(SEND_COMPOSE_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getGoalForComparionApi = async (data) => {
  try {
    const response = await Axios.get(
      GOALFOR_COMPARISION,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const eventDeleteApi = async (id) => {
  try {
    const response = await Axios.delete(
      EVENT_DELETE,
      {
        params: id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TransactionPreviewAddNote = async (data) => {
  try {
    const response = await Axios.post(TRANSACTION_ADD_NOTE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TransactionPreviewAddDocument = async (data) => {
  try {
    const response = await Axios.post(TRANSACTION_ADD_DOCUMENT, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ContactPreviewAddProperty = async (data) => {
  try {
    const response = await Axios.post(ADD_PROPERTY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteTransactionApi = async (data) => {
  try {
    const response = await Axios.delete(
      TRANSACTION_DELETE_IMAGE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditPropertyApi = async (data) => {
  try {
    const response = await Axios.put(`${EDIT_PROPERTY}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditTransactionNote = async (data) => {
  try {
    const response = await Axios.put(`${EDIT_TRANSACTION_NOTE}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getContactUserForDropDown = async (data) => {
  try {
    const response = await Axios.get(`${CONTACT_USERFOR_DROPDOWN}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getContactProperttyForDropDown = async (data) => {
  try {
    const response = await Axios.get(
      `${CONTACT_PROPERTY_FOR_DROPDOWN}`,
      {
        params: {
          contactid: data?.id,
          propertyId: data?.propertyId,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getActiveListingForEvent = async () => {
  try {
    const response = await Axios.get(`${ACTIVE_LISTING_EVENT_PROPERTY}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPraticularPropertyByApi = async (data) => {
  try {
    const response = await Axios.get(
      PARCTICULAR_PROPERTYBYID,
      {
        params: {
          propertyId: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllSuggestpropertiesApi = async (data) => {
  try {
    const response = await Axios.get(
      All_SUGGEST_PROPERTIES,
      {
        params: {
          propertytype: data?.propertytype,
          CountryName: data?.CountryName,
          state: data?.state,
          Subarea: data?.Subarea,
          city: data?.city,
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
          minBed: data?.minBed,
          maxBed: data?.maxBed,
          minBath: data?.minBath,
          maxBath: data?.maxBath,
          minpricrange: data?.minpricrange,
          maxpricrange: data?.maxpricrange,
          minArea: data?.minArea,
          maxArea: data?.maxArea,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TransationDeleteNotesApi = async (id) => {
  try {
    const response = await Axios.delete(
      TRANSACTION_DELETE_NOTES,
      {
        params: id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getChooseContactSuggestPropertiesApi = async (data) => {
  try {
    const response = await Axios.get(
      CHOOSE_CONTACT,
      {
        params: {
          rating: data?.rating,
          primary_contact_owner: data?.primary_contact_owner,
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editclosing = async (data) => {
  try {
    const response = await Axios.put(EDIT_ADDCLOSING, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddCountryApi = async (data) => {
  try {
    const response = await Axios.post(ADD_COUNTRY, data, {
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddStateApi = async (data) => {
  try {
    const response = await Axios.post(ADD_STATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const UpdateStateApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_STATE_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getStatebyId = async (data) => {
  try {
    const response = await Axios.get(
      GET_STATE_BY_ID,
      {
        params: {
          stateID: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddCityApi = async (data) => {
  try {
    const response = await Axios.post(ADD_CITY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddRegionApi = async (data) => {
  try {
    const response = await Axios.post(ADD_REGION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddServicesApi = async (data) => {
  try {
    const response = await Axios.post(ADD_SERVICES, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const UpdateServicesApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_SERVICE, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetAllServicesApi = async (data) => {
  try {
    let response;
    if (!data?.name || data?.name?.length === 0) {
      response = await Axios.get(
        ALL_SERVICES,
        {
          params: {
            Page: data?.Page,
            serviceType: data?.serviceType,
            ItemsPerPage: data?.ItemsPerPage,
            isActive: data?.isActive === "Active" ? true : false,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      response = await Axios.get(
        ALL_SERVICE_SEARCH,
        {
          params: {
            name: data?.name,
            Page: 1,
            ItemsPerPage: 10,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    }

    if (response?.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllCountryApi = async (data) => {
  try {
    const response = await Axios.get(ADD_COUNTRY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getInactivecontactAPi = async (data) => {
  try {
    const response = await Axios.get(INACTIVECONTACT_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getParticiPentsEventApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_PARTICIPENTS,
      {
        params: {
          name: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getperticularservicebyid = async (data) => {
  try {
    const response = await Axios.get(
      GET_PERTICULAR_SERVICE,
      {
        params: {
          Id: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getperticularTagbyid = async (data) => {
  try {
    const response = await Axios.get(
      GET_PERTICULAR_TAG,
      {
        params: {
          Id: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getClosingViewDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_CLOSINGVIEWDATA,
      {
        params: {
          closingid: data?.closingid,
          listStatus: data?.listStatus,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getNotificationApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_NOTIFICATION,
      {
        params: {
          userId: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getNotificationApiRead = async (data) => {
  try {
    const response = await Axios.get(
      GET_NOTIFICATION_READ,
      {
        params: {
          userId: data,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const MarkNotificationApi = async (data) => {
  try {
    const response = await Axios.post(MARK_NOTIFICATION_2ND, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ConfirmCmaThroughnotification = async (data) => {
  try {
    const response = await Axios.post(CONFIRM_CMA_NOTIFICATION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAssignedDropDownApi = async (data) => {
  try {
    const response = await Axios.get(GET_ASSIGNED_DROPDOWN, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getUserAdmintypeApi = async (data) => {
  try {
    const response = await Axios.get(GET_USER_ADMINTYPE, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const Addemailtemplate = async (data) => {
  try {
    const response = await Axios.post(ADD_EMAIL_TEMPLATE, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSmsTemplate = async (data) => {
  try {
    const response = await Axios.post(ADD_SMS_TEMPLATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetSmsTemplate = async (data) => {
  try {
    const response = await Axios.get(
      GET_SMS_TEMPLATE,
      {
        params: {
          smsfolderid: data?.folderId,
          search: data?.search,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSmsTemplateFolder = async (data) => {
  try {
    const response = await Axios.post(ADD_SMS_TEMPFOLDER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddEmailTemplateFolder = async (data) => {
  try {
    const response = await Axios.post(ADD_EMAIL_TEMPFOLDER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetTemplateBodyDataAfterBRAKET = async (data) => {
  try {
    const response = await Axios.post(Get_BRACKETREPLACE_FROM_BACKEND, data, {
      Headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetEmailTemplateFolder = async (data) => {
  try {
    const response = await Axios.get(
      GET_EMAIL_TEMPFOLDER,
      {
        params: {
          search: data?.search,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetSmsTemplateFolder = async (data) => {
  try {
    const response = await Axios.get(
      GET_SMS_TEMPFOLDER,
      {
        params: {
          search: data?.search,
        },
      },

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetEmailTemplate = async (data) => {
  try {
    const response = await Axios.get(
      GET_EMAIL_TEMPLATE,
      {
        params: {
          emailtemaplaitefolderid: data?.folderId,
          search: data?.search,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetMarketingTemplateDropDown = async (data) => {
  try {
    const response = await Axios.get(GET_MARKETING_DROPDOWN, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddEmailSendTemplate = async (data) => {
  try {
    const response = await Axios.post(ADD_SENDEMAIL_TEMPLATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSmsSendTemplate = async (data) => {
  try {
    const response = await Axios.post(ADD_SENDSMS_TEMPLATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeleteEmailTempalte = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_EMAIL_TEMPLATE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DeleteBrochureTemplate = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_BROCHURE_TEMPLATE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DeleteDirectMailerTemplate = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_DIRECTMAILERTEMPLATE_TEMPLATE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddMailerTempalte = async (data) => {
  try {
    const response = await Axios.post(
      ADD_MAILER_TEMPLATE,
      data,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const RenameTemplates = async ({ data, type }) => {
  try {
    let mainapi;
    if (type === 1) {
      mainapi = RENAME_DIRECTMAILER_TEMPLATE_FOLDERNAME;
    } else if (type === 2) {
      mainapi = RENAME_BROCHURE_TEMPLATE_FOLDERNAME;
    } else if (type === 3) {
      mainapi = RENAME_EMAIL_TEMPLATE_FOLDERNAME;
    } else {
      mainapi = RENAME_SMS_TEMPLATE_FOLDERNAME;
    }
    if (type === 1 || type === 2) {
      const response = await Axios.put(mainapi, data, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        return { res: response.data };
      } else return response.data;
    } else {
      const response = await Axios.post(mainapi, data, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        return { res: response.data };
      } else return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const DeleteTemplates = async ({ data, type }) => {
  try {
    let mainapi;
    if (type === 1) {
      mainapi = DELETE_DIRECTMAILER_TEMPLATE_FOLDERNAME;
    } else if (type === 2) {
      mainapi = DELETE_BROCHURE_TEMPLATE_FOLDERNAME;
    } else if (type === 3) {
      mainapi = DELETE_EMAIL_TEMPLATE_FOLDERNAME;
    } else {
      mainapi = DELETE_SMS_TEMPLATE_FOLDERNAME;
    }
    if (type === 1 || type === 2) {
      const response = await Axios.post(`${mainapi}?id=${data}`, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        return { res: response.data };
      } else return response.data;
    } else {
      const response = await Axios.post(mainapi, data, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        return { res: response.data };
      } else return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const GEtMailerTempalte = async (data) => {
  try {
    const response = await Axios.get(
      GET_MAILER_TEMPLATE,
      {
        params: {
          search: data?.search,
        },
      },

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditEmailtemplate = async (data) => {
  try {
    const response = await Axios.put(EDIT_EMAILTEMPLATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditSmstemplate = async (data) => {
  try {
    const response = await Axios.put(EDIT_SMS_TEMPLATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddTwostepVerficationApi = async (data) => {
  try {
    const response = await Axios.put(TWO_STEP_UPDATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddBrochureFolder = async (data) => {
  try {
    const response = await Axios.post(ADD_BROCHURE_FOLDER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetBrochureFolder = async (data) => {
  try {
    const response = await Axios.get(
      GET_BROCHURE_FOLDER,
      {
        params: {
          search: data?.search,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeleteSmsTemapltes = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_SMS_TEMPLATES,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const AddSequence = async (data) => {
  try {
    const response = await Axios.post(
      ADD_SEQUENCE,
      data,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSequenceSetting = async (data) => {
  try {
    const response = await Axios.post(ADD_SEQUENCE_SETTING, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSequenceShare = async (data) => {
  try {
    const response = await Axios.post(ADD_SEQUENCE_SHARE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const sequanceUpdateStatus = async (data) => {
  try {
    const response = await Axios.put(SEQUANCE_STATUS_UPDATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const sequanceUpdateApi = async (data) => {
  try {
    const response = await Axios.put(SEQUANCE_UPDATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const sequanceSettingUpdateApi = async (data) => {
  try {
    const response = await Axios.put(SEQUANCE_SETTING_UPDATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPerticulaSequanceApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_PERTICULARSEQUANCE,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPerticulaSequanceTaskApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_PERTICULAR_SEQUANCE_TASK,
      {
        params: {
          sequenceid: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const outLookAuthApi = async () => {
  try {
    const response = await Axios.post(OUT_LOOK_AUTH, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const outLookCreateEventApi = async (data, id, ParentEventId) => {
  try {
    const response = await axios.post(
      OUT_CREATE_EVENT,
      data,
      {
        params: {
          EventId: id,
          ParentEventId: ParentEventId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const outLookUpdateEventApi = async (data, id) => {
  try {
    const response = await axios.patch(
      OUT_UPDATE_EVENT,
      data,
      {
        params: {
          EventId: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error("something went wrong");
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddDirectMailerTemplate = async (data, id) => {
  try {
    const response = await Axios.post(ADD_DIRECT_MAILER_TEMPLATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const UpdateDirectMailerTemplate = async (data, id) => {
  try {
    const response = await Axios.put(UPDATE_DIRECT_MAILER_TEMPLATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getDirectMailerTemplate = async (data, id) => {
  try {
    const response = await Axios.get(
      GET_DIRECT_MAILER_TEMPLATE,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAutoSuggestcountryApi = async (data) => {
  try {
    const response = await Axios.get(
      AUTO_SUGGESTCOUNTRY,
      {
        params: {
          name: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAutoSuggestcityApi = async (data) => {
  try {
    const response = await Axios.get(
      AUTO_SUGGESTCITYAPI,
      {
        params: {
          name: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllCountriesApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_ALLCOUNTRIES,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCountryUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      COUNTRY_UPDATE,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStateUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_STATE,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCityUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_CITYSTATUS,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getRegionUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_REGIONSTATUS,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getTagUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_TAG,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AreaUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_AREA,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SubAreaUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_SUBAREA,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AnnouementUpdateApi = async (id) => {
  try {
    const response = await Axios.put(
      UPDATE_ANNOUCEMENT,
      {
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStateAutoSuggestApi = async (data) => {
  try {
    const response = await Axios.get(
      AUTO_SUGGESTSTATE,
      {
        params: {
          name: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getRegionAutoSuggestApi = async (data) => {
  try {
    const response = await Axios.get(
      AUTO_SUGGESTREGION,
      {
        params: {
          name: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getActiveAnnouncementToday = async (data) => {
  try {
    const response = await Axios.get(
      ACTIVE_ANNOUNCEMENT_TODAY,
      {
        params: {
          userId: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getActiveViewedAnnouncementToday = async (data) => {
  try {
    const response = await Axios.get(
      VIEWED_ANNOUNCEMENT_TODAY,
      {
        params: {
          userId: data,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStateAllDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_ALLSTATEDATA,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const searchStateAllDataApisuggest = async (data) => {
  try {
    const response = await Axios.get(
      SEARCH_ALLSTATEDATA,
      {
        params: {
          name: data.name,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCityAllDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GEt_ALLCITYDATA,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const searchCityAllDataApi = async (data) => {
  try {
    const response = await Axios.get(
      SEARCH_ALLCITYDATA,
      {
        params: {
          name: data?.name,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getRegionAllDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GEt_ALLREGIONDATA,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const searchRegionAllDataApi = async (data) => {
  try {
    const response = await Axios.get(
      SEARCH_ALLREGIONDATA,
      {
        params: {
          name: data?.name,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllTagsDataApi = async (data) => {
  try {
    let response;
    if (!data?.name || data?.name?.length === 0) {
      response = await Axios.get(
        GET_ALLTAGS,
        {
          params: {
            CreatedBy: data?.CreatedBy,
            Page: data?.Page,
            TagType: data?.TagType,
            ItemsPerPage: data?.ItemsPerPage,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      response = await Axios.get(
        ALL_TAG_SEARCH,
        {
          params: {
            name: data?.name,
            Page: 1,
            ItemsPerPage: 10,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    }

    // const response = await Axios.get(
    //   GET_ALLTAGS,
    //   {
    //     params: {
    //       CreatedBy: data?.CreatedBy,
    //       Page: data?.Page,
    //       TagType: data?.TagType,
    //       ItemsPerPage: data?.ItemsPerPage,
    //     },
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddTagApi = async (data) => {
  try {
    const response = await Axios.post(ADD_TAG, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UpdateTagApi = async (data) => {
  try {
    const response = await Axios.put(UPDATE_TAG_ROUTE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddAreaApi = async (data) => {
  try {
    const response = await Axios.post(ADD_AREA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddSubAreaApi = async (data) => {
  try {
    const response = await Axios.post(ADD_SUBAREA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllAreaDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_ALL_AREA,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const searchAllAreaDataApi = async (data) => {
  try {
    const response = await Axios.get(
      SEARCH_ALL_AREA,
      {
        params: {
          name: data.name,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllSubreaDataApi = async (data) => {
  try {
    const response = await Axios.get(
      GET_ALL_SUBAREA,
      {
        params: {
          Page: data?.Page,
          ItemsPerPage: data?.ItemsPerPage,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const searchAllSubreaDataApi = async (data) => {
  try {
    const response = await Axios.get(
      SEARCH_ALL_SUBAREA,
      {
        params: {
          name: data?.name,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAllDataAnnoucemntApi = async (data) => {
  try {
    let response;
    if (!data?.name || data?.name?.length === 0) {
      response = await Axios.get(
        GET_ALLANNOUCEMNET,
        {
          params: {
            Page: data?.Page,
            ItemsPerPage: data?.ItemsPerPage,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      response = await Axios.get(
        ALL_ANNOUNCEMENT_SEARCH,
        {
          params: {
            name: data?.name,
            Page: 1,
            ItemsPerPage: 10,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    }

    // const response = await Axios.get(
    //   GET_ALLANNOUCEMNET,
    //   {
    //     params: {
    //       Page: data?.Page,
    //       ItemsPerPage: data?.ItemsPerPage,
    //     },
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addAnnoucementApi = async (data) => {
  try {
    const response = await Axios.post(`${ADD_ANNOUCEMENT}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const updateAnnoucementApi = async (data) => {
  try {
    const response = await Axios.put(`${UPDATE_ANNOUCEMENT_DATA}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    // toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
    // throw "something went wrong"t
    // toast.error("something went wrong");
  }
};

export const getAnnouncementBYId = async (data) => {
  try {
    const response = await Axios.get(GET_ANNOUNCEMENTBY_ID, {
      params: { Id: data },
      headers: {
        "Content-Type": "application/json",
        // Add other headers if needed
      },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const DeletedAnnouncementImages = async (data) => {
  try {
    const response = await Axios.delete(
      DELETE_ANNOUNCEMENT_IMAGE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeletedContactPreviewDocuments = async (data) => {
  try {
    const response = await Axios.delete(
      CONTACT_PREVIEW_DOCUMENT_DELETE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addSupportApi = async (data) => {
  try {
    const response = await Axios.post(`${ADD_SUPPORT}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getImageUrlTemplate = async (data) => {
  try {
    const response = await Axios.post(`${POST_IMAGE_URL_CDN}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const acceptAnnoucementApi = async (data) => {
  try {
    const response = await Axios.post(`${ACCEPT_ANNOUCEMENT}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAllPropertyData = async (data) => {
  try {
    const response = await Axios.get(`${GET_PROPERTY}name=${data}`, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const differencebetweentwodays = ({ date1, date2 }) => {
  const date3 = new Date(date1);
  const date4 = new Date(date2);

  // Find the difference in milliseconds
  const differenceInMilliseconds = date4 - date3;

  // Convert the difference to other units (e.g., seconds, minutes, hours)

  if (differenceInMilliseconds < 0) {
    return true;
  } else {
    return false;
  }
};

export const Notification_Handler = async (data) => {
  try {
    const response = await Axios.put(HANDLE_NOTIFICATIONS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const updateSequenceStartedData = async (data) => {
  try {
    const response = await Axios.post(
      `${SEQUENCE_START_API}`,
      {
        changeType: data?.changeType,
        notificationUrl: `${Base_Url}GraphNotification/GraphNotificationHook`,
        resource: data?.resource,
        expirationDateTime: new Date(
          new Date().getTime() + 3 * 24 * 60 * 60 * 1000
        ).toISOString(),
        clientState: data?.clientState,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    // toast.error(err);
    if (err.response) throw err.response.data;
    else throw err.message;
    // throw "something went wrong"t
    // toast.error("something went wrong");
  }
};
