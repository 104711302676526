import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MarkNotificationApi,
  addTaskDropDownApi,
  getAddCommonUserDropdownsApi,
  getAllCitiesApi,
  getAllLocalitiesApi,
  getAllLocalitiesApi2nd,
  getAllLocalitiesApiForMaster,
  getAllStateDataApi,
  getAllTimeZonesDropDownOptionsApi,
  getAssignedDropDownApi,
  getAutoSuggestcityApi,
  getAutoSuggestcountryApi,
  getCityApi,
  getCityAutoApi,
  getCommonDropDownOptionsApi,
  getCountryApi,
  getHomePageCOntactdataApi,
  getNotificationApi,
  getNotificationApiRead,
  getRegionAutoSuggestApi,
  getStateApi,
  getStateAutoSuggestApi,
  getSuggestedUserApi,
  getTransactionClosingDropdownApi,
} from "../../Api/Services";
import { toast } from "react-toastify";

export const getCountryData = createAsyncThunk(
  "common/getCountryData",
  async () => {
    try {
      const result = await getCountryApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getStateData = createAsyncThunk(
  "common/getStateData",
  async (data) => {
    try {
      const result = await getStateApi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getNotificationReducerData = createAsyncThunk(
  "common/getNotificationReducerData",
  async (data, { getState, dispatch }) => {
    const state = getState();
    try {
      const viewednotificationdatathroughapi = await getNotificationApiRead(
        data
      );
      const apiResult = await getNotificationApi(data);
      if (state?.common?.notificationData?.eventList !== undefined) {
        if (
          state?.common?.notificationData?.eventList <
          apiResult?.data?.eventList
        ) {
          dispatch(handleisNewNOtificaion(true));
          setTimeout(() => {
            dispatch(handleisNewNOtificaion(false));
          }, 2000);
        }
      }

      const viewedarcieveddatathroughapionlyid =
        viewednotificationdatathroughapi?.res?.data?.length > 0 === true
          ? viewednotificationdatathroughapi?.res?.data
              ?.filter((item) => item?.isArchived === true)
              ?.map((item) => item?.notificationId)
          : [];

      const viewedarcieveddatathroughapionlyidReaded =
        viewednotificationdatathroughapi?.res?.data?.length > 0 === true
          ? viewednotificationdatathroughapi?.res?.data
              ?.filter((item) => item?.isRead === true)
              ?.map((item) => item?.notificationId)
          : [];

      const allnotificationdata = apiResult?.res?.data?.eventList;

      let unviewedNotifications = allnotificationdata?.filter(
        (item) => !viewedarcieveddatathroughapionlyidReaded.includes(item.id)
      );

      const unread = unviewedNotifications?.length;

      const withoudarchieveddata = apiResult?.res?.data?.eventList?.filter(
        (item) => !viewedarcieveddatathroughapionlyid?.includes(item?.id)
      );
      const witharchieveddata = apiResult?.res?.data?.eventList?.filter(
        (item) => viewedarcieveddatathroughapionlyid?.includes(item?.id)
      );

      return {
        ...apiResult.res?.data,
        unReadCount: unread,
        viewednotificationdatathroughapi: viewednotificationdatathroughapi,
        viewednotificationdatathoroudhapionliids:
          viewedarcieveddatathroughapionlyidReaded,
        notificationwithoutarchieved: withoudarchieveddata,
        notificationwitharchieved: witharchieveddata,
      };
    } catch (error) {
      // toast.error(error.message);
      // You might also want to rethrow the error to propagate it up the chain
      throw error;
    }
  }
);

export const marknotificaionhandler = createAsyncThunk(
  "common/marknotificaionhandler",
  async (data, { dispatch }) => {
    try {
      const result = await MarkNotificationApi(data);
      if (result?.res) {
        dispatch(getNotificationReducerData(data?.userId));
      }
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getAssignedDropDownReducerData = createAsyncThunk(
  "common/getAssignedDropDownReducerData",
  async (data) => {
    try {
      const result = await getAssignedDropDownApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getLocalitiesData = createAsyncThunk(
  "common/getLocalitiesData",
  async (data) => {
    try {
      const result = await getAllLocalitiesApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getLocalitiesDataForMaster = createAsyncThunk(
  "common/getLocalitiesDataForMaster",
  async (data) => {
    try {
      const result = await getAllLocalitiesApiForMaster(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getTimeFrameDataReducer = createAsyncThunk(
  "common/getLocalitiesData",
  async () => {
    try {
      const result = await addTaskDropDownApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getLocalitiesData2nd = createAsyncThunk(
  "common/getLocalitiesData2nd",
  async (data) => {
    try {
      const result = await getAllLocalitiesApi2nd(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getCitiesData = createAsyncThunk(
  "common/getCitiesData",
  async (data) => {
    try {
      const result = await getAllCitiesApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getStateDataValue = createAsyncThunk(
  "common/getStateDataValue",
  async (data) => {
    try {
      const result = await getAllStateDataApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getSuggestedUserReducer = createAsyncThunk(
  "common/getSuggestedUserReducer",
  async (data) => {
    try {
      const result = await getSuggestedUserApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCityData = createAsyncThunk(
  "common/getCityData",
  async (data) => {
    try {
      const result = await getCityApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCommonDropDownOptions = createAsyncThunk(
  "common/getCommonDropDownOptions",
  async () => {
    try {
      const result = await getCommonDropDownOptionsApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getAllTimeZoneDropDownOptions = createAsyncThunk(
  "common/getAllTimeZoneDropDownOptions",
  async () => {
    try {
      const result = await getAllTimeZonesDropDownOptionsApi();

      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCityAutoSelect = createAsyncThunk(
  "common/getCityAutoSelect",
  async (data) => {
    try {
      const result = await getCityAutoApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCountryAutosuggest = createAsyncThunk(
  "common/getCountryAutosuggest",
  async (data) => {
    try {
      const result = await getAutoSuggestcountryApi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getStateAutosuggest = createAsyncThunk(
  "common/getStateAutosuggest",
  async (data) => {
    try {
      const result = await getStateAutoSuggestApi(data);

      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getRegionAutosuggest = createAsyncThunk(
  "common/getRegionAutosuggest",
  async (data) => {
    try {
      const result = await getRegionAutoSuggestApi(data);

      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCityAutosuggest = createAsyncThunk(
  "common/getCityAutosuggest",
  async (data) => {
    try {
      const result = await getAutoSuggestcityApi(data);

      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getListingDropDown = createAsyncThunk(
  "common/getListingDropDown",
  async (data) => {
    try {
      const result = await getTransactionClosingDropdownApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCommonAddUsersDropdownReducers = createAsyncThunk(
  "common/getCommonAddUsersDropdownReducers",
  async () => {
    try {
      const result = await getAddCommonUserDropdownsApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getCommonSupportdropdownreducers = createAsyncThunk(
  "common/getCommonSupportdropdownreducers",
  async () => {
    try {
      const result = await getAddCommonUserDropdownsApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getHomePageContactData = createAsyncThunk(
  "common/getHomePageContactData",
  async (data) => {
    try {
      const result = await getHomePageCOntactdataApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
const commonSlice = createSlice({
  name: "common",
  initialState: {
    countryData: [],
    stateData: [],
    cityData: [],
    commonDropDowns: {},
    AllLocalities: [],
    AllLocalities2nd: [],
    AllCities: [],
    CitiesData: [],
    AllStatedata: [],
    AutoSuggestcountryData: [],
    AutoSuggestStateData: [],
    AutoSuggestRegionData: [],
    AutoSuggestCityData: [],
    AllAutoCityData: [],
    ListingDropDownData: [],
    AssignedDropDown: [],
    isOpenRemoveModal: false,
    perticularUniqIdDelete: "",
    addCommonUserDropdownsData: [],
    Supportdropdownoptions: [],
    AllTimeZones: [],
    notificationData: [],
    suggestedUserData: [],
    isOpenNoteDelete: false,
    isOpenCallDelete: false,
    isOpenMeetingDelete: false,
    isOpenTextDelete: false,
    isOpenInteractionDelete: false,
    isOpenEmailDelete: false,
    isOpenPropertyRemove: false,
    isopenEamilTemapltes: false,
    tokenapicalling: false,
    sidenavopenclose: false,
    istiltnotification: false,
    isNewNotification: false,
    ismyreportloaded: false,
    AllLocalitiesformaster: [],
    homepagecontactdata: [],
  },
  reducers: {
    handleOpenRemoveModal: (state, { payload }) => {
      state.isOpenRemoveModal = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemovePropertyModal: (state, { payload }) => {
      state.isOpenPropertyRemove = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveEmailTemplates: (state, { payload }) => {
      state.isopenEamilTemapltes = true;
      state.perticularUniqIdDelete = payload;
    },
    handleismyreportLoader: (state, { payload }) => {
      state.ismyreportloaded = payload;
    },
    handleOpenRemoveEmailModal: (state, { payload }) => {
      state.isOpenEmailDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveNoteModal: (state, { payload }) => {
      state.isOpenNoteDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveCallModal: (state, { payload }) => {
      state.isOpenCallDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveMeetingModal: (state, { payload }) => {
      state.isOpenMeetingDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveTextModal: (state, { payload }) => {
      state.isOpenTextDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleOpenRemoveInteractionModal: (state, { payload }) => {
      state.isOpenInteractionDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleCloseRemoveTransactionNoteModal: (state, { payload }) => {
      state.isOpenTransactionDelete = true;
      state.perticularUniqIdDelete = payload;
    },
    handleCloseRemoveModal: (state) => {
      state.isOpenRemoveModal = false;
    },
    handleCloseRemoveNoteModal: (state) => {
      state.isOpenNoteDelete = false;
    },
    handleCloseRemoveCallModal: (state) => {
      state.isOpenCallDelete = false;
    },
    handleCloseRemoveMeetingModal: (state) => {
      state.isOpenMeetingDelete = false;
    },
    handleCloseRemoveTextModal: (state) => {
      state.isOpenTextDelete = false;
    },
    handleCloseRemoveInteractionModal: (state) => {
      state.isOpenInteractionDelete = false;
    },
    handleCloseRemoveEmailModal: (state) => {
      state.isOpenEmailDelete = false;
    },
    handleCloseRemovePropertiesModal: (state) => {
      state.isOpenPropertyRemove = false;
    },
    handleCloseRemoveEmailTemplates: (state) => {
      state.isopenEamilTemapltes = false;
    },
    handletokenapicalling: (state, { payload }) => {
      state.tokenapicalling = payload;
    },
    handlesidenavopenclose: (state, { payload }) => {
      state.sidenavopenclose = payload;
    },
    handleisTiltNotification: (state, { payload }) => {
      state.istiltnotification = payload;
    },
    handleisNewNOtificaion: (state, { payload }) => {
      state.isNewNotification = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryData.fulfilled, (state, { payload }) => {
        state.countryData = payload;
      })
      .addCase(getStateData.fulfilled, (state, { payload }) => {
        state.stateData = payload;
      })
      .addCase(
        getAllTimeZoneDropDownOptions.fulfilled,
        (state, { payload }) => {
          state.AllTimeZones = payload;
        }
      )
      .addCase(getCountryAutosuggest.fulfilled, (state, { payload }) => {
        state.AutoSuggestcountryData = payload;
      })
      .addCase(getStateAutosuggest.fulfilled, (state, { payload }) => {
        state.AutoSuggestStateData = payload;
      })
      .addCase(getRegionAutosuggest.fulfilled, (state, { payload }) => {
        state.AutoSuggestRegionData = payload;
      })
      .addCase(getCityData.fulfilled, (state, { payload }) => {
        state.cityData = payload;
      })
      .addCase(getCommonDropDownOptions.fulfilled, (state, { payload }) => {
        state.commonDropDowns = payload;
      })
      .addCase(getLocalitiesData.fulfilled, (state, { payload }) => {
        state.AllLocalities = payload;
      })
      .addCase(getLocalitiesData2nd.fulfilled, (state, { payload }) => {
        state.AllLocalities2nd = payload;
      })
      .addCase(getLocalitiesDataForMaster.fulfilled, (state, { payload }) => {
        state.AllLocalitiesformaster = payload;
      })
      .addCase(getCitiesData.fulfilled, (state, { payload }) => {
        state.AllCities = payload;
      }) 
      .addCase(getStateDataValue.fulfilled, (state, { payload }) => {
        state.AllStatedata = payload;
      }) 
      .addCase(getCityAutosuggest.fulfilled, (state, { payload }) => {
        state.AutoSuggestCityData = payload;
      })
      .addCase(getCityAutoSelect.fulfilled, (state, { payload }) => {
        state.AllAutoCityData = payload;
      })
      .addCase(getListingDropDown.fulfilled, (state, { payload }) => {
        state.ListingDropDownData = payload;
      })
      .addCase(
        getCommonAddUsersDropdownReducers.fulfilled,
        (state, { payload }) => {
          state.addCommonUserDropdownsData = payload;
        }
      )
      .addCase(
        getCommonSupportdropdownreducers.fulfilled,
        (state, { payload }) => {
          state.Supportdropdownoptions = payload;
        }
      )
      .addCase(getSuggestedUserReducer.fulfilled, (state, { payload }) => {
        state.suggestedUserData = payload;
      })
      .addCase(getNotificationReducerData.fulfilled, (state, { payload }) => {
        state.notificationData = payload;
      })
      .addCase(
        getAssignedDropDownReducerData.fulfilled,
        (state, { payload }) => {
          state.AssignedDropDown = payload;
        }
      )
      .addCase(getHomePageContactData.fulfilled, (state, { payload }) => {
        state.homepagecontactdata = payload;
      });
  },
});

export const {
  commonAction,
  handleOpenRemoveMeetingModal,
  handleOpenRemoveCallModal,
  handleCloseRemoveCallModal,
  handleOpenRemoveModal,
  handleCloseRemoveMeetingModal,
  handleCloseRemoveNoteModal,
  handleOpenRemoveTextModal,
  handleOpenRemoveNoteModal,
  handleCloseRemoveTextModal,
  handleOpenRemoveInteractionModal,
  handleOpenRemoveEmailModal,
  handleCloseRemoveEmailModal,
  handleCloseRemoveInteractionModal,
  handleCloseRemovePropertiesModal,
  handleCloseRemoveEmailTemplates,
  handleOpenRemovePropertyModal,
  handleOpenRemoveEmailTemplates,
  handleCloseRemoveModal,
  handletokenapicalling,
  handlesidenavopenclose,
  handleisTiltNotification,
  handleisNewNOtificaion,
  handleismyreportLoader,
} = commonSlice.actions;

export default commonSlice;
