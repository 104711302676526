import { Tooltip } from "@mui/material";
import moment, { relativeTimeRounding } from "moment";
import { parsePhoneNumber } from "react-phone-number-input";

export const colourStylesWhite = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#6A7ABC" : "none",
      color: isFocused ? "#FFF" : "#70747B",
      "& .svgiconidentifier": {
        fill: isFocused ? "#fff" : "#667DD1",
      },
      "& .svgiconidentifier2": {
        fill: isFocused ? "#667DD1" : "#fff",
      },
      "& .strokeidentifier": {
        fill: isFocused ? "#667DD1" : "#fff",
      },
      borderBottom: "1px solid #E6E6E9",
      zIndex: isFocused ? "999999" : "999999",

      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
      "&:hover": {
        ".svgiconidentifier": {
          fill: "#fff",
        },
        ".svgiconidentifier2": {
          fill: "#667DD1",
        },
        ".strokeidentifier": {
          fill: "#667DD1",
        },
      },
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontFamily: ``,
    textTransform: "capitalize",

    color: "#70747B",
    backgroundColor: "#ffff",
    boxShadow: "none",
    padding: "2px 5px",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      // borderColor: "red",
      color: "#fff",
      backgroundColor: "#6A7ABC",
      "& svg": {
        fill: "#fff",
      },
      ".css-1wpf0s6": {
        color: "white",
      },
    },
    "&:focus": {
      color: "#fff",
    },
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "white", // Set the text color to white
  //   "&:hover": {
  //     color: "white",
  //   },
  //   "&:focus": {
  //     color: "white",
  //   },

  // }),
  input: (provided) => ({
    ...provided,

    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",

    borderColor: 0,
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#fff",
      fill: "#fff",
      "& svg": {
        fill: "#fff",
      },
    },
  }),
  singleValue: (base, { isFocused }) => ({
    ...base,
    color: "inherit",

    "&:hover": {
      color: "#FFF",
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#eaeeff",
      fontSize: "1rem",
      padding: "0",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
  input: (provided) => ({
    ...provided,
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),
};

export const colourStylesWhiteWithMargin = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#6A7ABC" : "none",
      color: isFocused ? "#FFF" : "#70747B",
      borderBottom: "1px solid #E6E6E9",
      zIndex: isFocused ? "999999" : "999999",

      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontFamily: ``,
    textTransform: "capitalize",

    color: "#70747B",
    backgroundColor: "#ffff",
    boxShadow: "none",
    padding: "2px 5px",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      // borderColor: "red",
      color: "#fff",
      backgroundColor: "#6A7ABC",
      "& svg": {
        fill: "#fff",
      },
      ".css-1wpf0s6": {
        color: "white",
      },
    },
    "&:focus": {
      color: "#fff",
    },
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "white", // Set the text color to white
  //   "&:hover": {
  //     color: "white",
  //   },
  //   "&:focus": {
  //     color: "white",
  //   },

  // }),
  input: (provided) => ({
    ...provided,

    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",

    borderColor: 0,
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#fff",
      fill: "#fff",
      "& svg": {
        fill: "#fff",
      },
    },
  }),
  singleValue: (base, { isFocused }) => ({
    ...base,
    color: "inherit",
    marginLeft: "11px",
    "&:hover": {
      color: "#FFF",
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#eaeeff",
      fontSize: "1rem",
      padding: "0",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
  input: (provided) => ({
    ...provided,
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),
};

export const colourStylesWhitewithouthoverchange = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#6A7ABC" : "none",
      color: isFocused ? "#FFF" : "#70747B",
      borderBottom: "1px solid #E6E6E9",
      zIndex: isFocused ? "999999" : "999999",

      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontFamily: ``,
    textTransform: "capitalize",

    color: "#70747B",
    backgroundColor: "#ffff",
    boxShadow: "none",
    padding: "2px 5px",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      // borderColor: "red",
      color: "#000",
      backgroundColor: "#fff",
      "& svg": {
        fill: "#000",
      },
    },
    "&:focus": {
      color: "#000",
    },
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "white", // Set the text color to white
  //   "&:hover": {
  //     color: "white",
  //   },
  //   "&:focus": {
  //     color: "white",
  //   },

  // }),
  input: (provided) => ({
    ...provided,
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "inherit",
      fill: "inherit",
      "& svg": {
        fill: "inherit",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "",
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#eaeeff",
      fontSize: "1rem",
      padding: "0",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
  input: (provided) => ({
    ...provided,
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),
};

export const colourStylesgrey = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#6A7ABC" : "none",
      color: isFocused ? "#FFF" : "#70747B",
      borderBottom: "1px solid #E6E6E9",
      zIndex: "999",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
      // cursor: "pointer",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    textTransform: "capitalize",
    fontfamily: "GillSansMedium",
    color: "#70747B",
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
    padding: "2px 5px",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6A7ABC",
      "& svg": {
        fill: "#fff",
      },
      ".css-1wpf0s6": {
        color: "white",
      },
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    zIndex: "99",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#fff",
      fill: "#fff",
      "& svg": {
        fill: "#fff",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#70747B",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#6A7ABC",
      },
    };
  },
  menu: (defaultStyles) => {
    return {
      ...defaultStyles,
      zIndex: "999999",
    };
  },
  input: (provided) => ({
    ...provided,
    minHeight: "33px",
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};

export const colourStylesgreywithoutcapitatlize = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#6A7ABC" : "none",

      color: isFocused ? "#FFF" : "#70747B",
      "& .svgiconidentifier": {
        fill: isFocused ? "#fff" : "#667DD1",
      },
      "& .svgiconidentifier2": {
        fill: isFocused ? "#667DD1" : "#fff",
      },
      "& .strokeidentifier": {
        fill: isFocused ? "#667DD1" : "#fff",
      },

      borderBottom: "1px solid #E6E6E9",
      zIndex: "999",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
      // cursor: "pointer",
      "&:hover": {
        ".svgiconidentifier": {
          fill: "#fff",
        },
        ".svgiconidentifier2": {
          fill: "#667DD1",
        },
        ".strokeidentifier": {
          fill: "#667DD1",
        },
        ".participant_defineclass": {
          fill: "#fff",
        },
      },
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    // textTransform: "capitalize",
    fontfamily: "GillSansMedium",
    color: "#70747B",
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
    padding: "2px 5px",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6A7ABC",
      "& svg": {
        fill: "#fff",
      },
      ".css-1wpf0s6": {
        color: "white",
      },
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    zIndex: "99",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#fff",
      fill: "#fff",
      "& svg": {
        fill: "#fff",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#70747B",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#6A7ABC",
      },
    };
  },
  menu: (defaultStyles) => {
    return {
      ...defaultStyles,
      zIndex: "999999",
    };
  },
  input: (provided) => ({
    ...provided,
    minHeight: "33px",
    color: "white",
    "&:(not)hover": {
      color: "white",
    },
    "&:focus": {
      color: "white",
    },
    "&:not(:hover)": {
      color: "black",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};
export const homewhite = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#fff" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    textTransform: "capitalize",
    fontfamily: "GillSansMedium",
    color: "#70747B",
    backgroundColor: "none",
    cursor: "pointer",
    boxShadow: "none",
    paddingRight: 0,
    padding: "2px 5px",
    margin: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",
      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};
export const fixWidthStyle = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f7fafb" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      cursor: "pointer",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontfamily: "GillSansMedium",
    textTransform: "capitalize",
    color: "#70747B",
    backgroundColor: "none",
    cursor: "pointer",
    boxShadow: "none",
    paddingRight: 0,
    padding: "2px 5px",
    margin: 0,
    width: "100px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    cursor: "pointer",
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",
      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};

export const fixWidthStyle2nd = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f7fafb" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      cursor: "pointer",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontfamily: "GillSansMedium",
    textTransform: "capitalize",
    color: "#667DD1",
    backgroundColor: "none",
    cursor: "pointer",
    boxShadow: "none",
    paddingRight: 0,
    padding: "2px 5px",
    margin: 0,
    width: "100px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    cursor: "pointer",
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",
      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};

export const fixWidthStyle3rd = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f7fafb" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      cursor: "pointer",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontfamily: "GillSansMedium",
    textTransform: "capitalize",
    color: "#667DD1",
    backgroundColor: "none",
    cursor: "pointer",
    boxShadow: "none",
    paddingRight: 0,
    padding: "2px 5px",
    margin: 0,
    width: "116px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    cursor: "pointer",
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",
      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "none",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};
export const homeblue = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f7fafb" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontfamily: "GillSansMedium",
    textTransform: "capitalize",
    color: "#667DD1",
    backgroundColor: "none",
    cursor: "pointer",
    boxShadow: "none",
    paddingRight: "0",
    padding: " 0",
    margin: "0",
    minWidth: "105px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",

      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};

export const homeblue2 = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f7fafb" : "none",
      color: isFocused ? "#70747B" : "#70747B",
      borderBottom: isFocused ? "1px solid #E6E6E9" : "1px solid #E6E6E9",
      zIndex: isFocused ? "999" : "null",
      cursor: "pointer",
      boxShadow: isFocused ? "none" : "none",
      marginTop: "0",
      marginBottom: "0",
      textTransform: "capitalize",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontfamily: "GillSansMedium",
    textTransform: "capitalize",
    color: "#667DD1",
    backgroundColor: "none",
    boxShadow: "none",
    paddingRight: "0",
    padding: " 0",
    margin: "0",
    cursor: "pointer",
    minWidth: "105px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    minWidth: "55px",
    cursor: "pointer",
    padding: "0",
    "& svg": {
      height: "15px",
      fill: "#70747B",
      "&:hover": {
        fill: "#70747B",
      },
    },
    "&:hover": {
      color: "#70747B",

      fill: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
    "&:hover": {
      color: "#FFF",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#fff',
  // })
};
export const selctStyleEmail = {
  option: (styles, {}) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      color: "#70747B",
      borderBottom: "none",
      zIndex: "99",
      fontSize: "1rem",
      boxShadow: "none",
      marginTop: "0",
      marginBottom: "0",
    };
  },
  control: (base) => ({
    ...base,
    border: 0,
    fontFamily: ``,
    textTransform: "capitalize",
    color: "#70747B",
    backgroundColor: "none",
    boxShadow: "none",
    padding: "2px 5px",
    fontSize: "1rem",
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      color: "#70747B",
      "& svg": {
        fill: "#70747B",
      },
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    borderColor: 0,
    padding: "0",
    "& svg": {
      display: "none",
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#eaeeff",
      fontSize: "1rem",
    };
  },

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    "&:hover": {
      backgroundColor: "#eaeeff",
    },
  }),
  // input: (base, state) => ({
  //   ...base,
  //   color: '#70747B',
  // })
};

export const formatDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const formatPercentace = (total, vacant) => {
  let calculation = (vacant / total) * 100;

  return !isNaN(calculation) ? calculation.toFixed(2) : 0;
};
export const formatTimeAmPm = (date) => {
  if (date) {
    return moment(date).format("hh:mm A");
  } else {
    return "-";
  }
};
// export const formatTime = (data) => {
//   const _date = new Date(data);

//   let date = _date.getDate();
//   return { hour: "numeric", minute: "numeric", hour12: true };
// };

export const compareTime = (date, time) => {
  if (
    moment(date).format("DD MM YYYY") ===
    moment(new Date(time)).format("DD MM YYYY")
  ) {
    let beginningTime = moment(new Date(time), "h:mma");
    let endTime = moment(new Date(), "h:mma");
    return beginningTime.isBefore(endTime);
  } else return false;
};

export const formatCurrencyCode = (code, price) => {
  let numericPrice = parseFloat(price?.replace(/,/g, ""));

  let euro = Intl.NumberFormat(code || "USD", {
    style: "currency",
    currency: code || "USD",
  });

  return euro.format(numericPrice);
};
export const multiplelocalsupport = (date) => {
  return moment(date).format("lll");
};

export const fetchImageBinary = (imgRef, cropThumb) => {
  if (imgRef.current && cropThumb.width && cropThumb.height) {
    const canvas = document.createElement("canvas");
    canvas.width = cropThumb.width;
    canvas.height = cropThumb.height;
    const ctx = canvas.getContext("2d");

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    ctx.drawImage(
      imgRef.current,
      cropThumb.x * scaleX,
      cropThumb.y * scaleY,
      cropThumb.width * scaleX,
      cropThumb.height * scaleY,
      0,
      0,
      cropThumb.width,
      cropThumb.height
    );
    const croppedImageUrl = canvas.toDataURL("image/jpeg");
    const result = fetch(croppedImageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
        return file;
      });
    return result;
  }
};

function MonthAddition(date, additionvalue) {
  const originalDateStr = date;
  const [first, second] = originalDateStr.split("T");

  const originalDate = new Date(date);

  // add one day from the date
  originalDate.setDate(originalDate.getMonth() + Number(additionvalue));

  const originalDateStrs = originalDate.toISOString();

  // Split the string to separate date and time components
  const [datePart, timePart] = originalDateStrs.split("T");

  return `${datePart}T${second}`;
}

export const additionofyear = (date, additionvalue) => {
  const originalDateStr = date;
  const [first, second] = originalDateStr.split("T");

  const originalDate = new Date(date);

  // add one day from the date
  originalDate.setFullYear(originalDate.getFullYear() + Number(additionvalue));

  const originalDateStrs = originalDate.toISOString();

  // Split the string to separate date and time components
  const [datePart, timePart] = originalDateStrs.split("T");

  return `${datePart}T${second}`;
};

export const firstpartdate = (data) => {
  const originalDateStr = data;
  const originalDateStrs = originalDateStr.toISOString();
  const [first, second] = originalDateStrs.split("T");
  return first;
};

export const firstpartdatewithoutiso = (data) => {
  try {
    const originalDateStr = data;

    const [first, second] = originalDateStr.split("T");
    return first;
  } catch (err) {
    return data;
  }
};

export const secondpartdate = (data) => {
  const originalDateStr = data;

  const originalDateStrs = originalDateStr.toISOString();
  const [first, second] = originalDateStrs.split("T");

  return second;
};

export const secondpartdateindianstring = (data) => {
  const originalDateStr = data;

  const originalDateStrs = originalDateStr.toLocaleString();
  const [first, second] = originalDateStrs.split(",");

  return second?.trim();
};

export const differencebetweentwoday = (date1, date2) => {
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  const differenceInMilliseconds = endDate - startDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  let pushedarry = [];
  for (let i = 0; i <= differenceInDays; i++) {
    pushedarry.push(i);
  }

  return pushedarry;
};

export const differencebetweentwoyear = (date1, date2) => {
  const firstdate = new Date(date1);
  const seconddate = new Date(date2);

  const year1 = firstdate.getFullYear();
  const year2 = seconddate.getFullYear();

  return Math.abs(year2 - year1);
};

export const Framingdate = (date1, date2) => {
  const resultDate = new Date(date2);

  // Add the date from date2 to resultDate
  date2.setDate(date2.getDate() + date1.getDate());

  return resultDate;
};

export const addOneDayOnDate = (date, additionvalue) => {
  const originalDateStr = date;
  const [first, second] = originalDateStr.split("T");

  const originalDate = new Date(date);

  // add one day from the date
  originalDate.setDate(originalDate.getDate() + Number(additionvalue));

  const originalDateStrs = originalDate.toISOString();

  // Split the string to separate date and time components
  const [datePart, timePart] = originalDateStrs.split("T");

  return `${datePart}T${second}`;
};

export const firstpartdatewithadditiondate = (date) => {
  const originalDate = new Date(date);
  originalDate.setDate(originalDate.getDate() + Number(1));
  const originalDateStrs = originalDate.toISOString();
  const [datePart, timePart] = originalDateStrs.split("T");
  return datePart;
};

export const getDayOfWeek = (dateString) => {
  // Parse the date string in MM-DD-YYYY format
  const [month, day, year] = dateString.split("-").map(Number);

  // Months are 0-indexed in JavaScript, so we subtract 1 from the provided month
  const date = new Date(dateString);

  // Days of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the day of the week using getDay() method
  const dayIndex = date.getDay();

  // Return the corresponding day of the week
  return daysOfWeek[dayIndex];
};

export const removemarkformphone = (PhoneNumber) => {
  // Check if PhoneNumber is a non-empty string
  if (PhoneNumber && typeof PhoneNumber === "string") {
    // Remove hyphens
    const stringWithoutHyphens = PhoneNumber.replace(/-/g, "");

    // Add "+1" if not already present
    const finalPhoneNumber = stringWithoutHyphens.startsWith("+")
      ? stringWithoutHyphens
      : `+1${stringWithoutHyphens}`;

    return finalPhoneNumber;
  } else {
    return PhoneNumber;
  }
};

export const DateOfTheFulldate = (date) => {
  const desiredDate = new Date(date); // Create a Date object for the current date

  // Define an array of day names
  const dated = desiredDate.getDate();

  return dated;
};

export const MonthOfTheFulldate = (date) => {
  const desiredDate = new Date(date); // Create a Date object for the current date

  // Define an array of day names
  const dated = desiredDate.getMonth();

  return dated + 1;
};
export const DayNameOfWeek = (date) => {
  const desiredDate = new Date(date); // Create a Date object for the current date

  // Define an array of day names
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = desiredDate.getDay();

  // Get the day name based on the day of the week
  const dayName = dayNames[dayOfWeek];

  // Display the day name

  return dayName;
};

export const addOneMonth = ({ date, additionvalue = 1 }) => {
  const [first, second] = date.split("T");

  const datee = new Date(date);
  const year = datee.getFullYear();
  const month = datee.getMonth();
  const day = datee.getDate();

  // Calculate the next month
  let newYear = year;
  let newMonth = month + additionvalue;

  if (newMonth === 12) {
    newYear++;
    newMonth = 0; // Reset to January (0-based)
  }

  // Determine the last day of the new month (to handle edge cases)
  const lastDayOfNewMonth = new Date(newYear, newMonth + 1, 0).getDate();

  // Use the minimum of the day from the original date and the last day of the new month
  const newDay = Math.min(day, lastDayOfNewMonth);

  // Create a new Date object with the adjusted date
  const newDate = new Date(newYear, newMonth, day);

  return newDate;
};

export const constgetdesiredmonth = (datevalue) => {
  const inputDate = new Date(datevalue);

  // Get the date of the next day
  const nextDayDate = new Date(inputDate);
  nextDayDate.setDate(inputDate.getDate() + 1);

  // Display the date of the next day

  // Get the ISO formatted date of the next day
  const isoFormattedDate = nextDayDate.toISOString();
  return isoFormattedDate;
};

export const recureencedaysofWeek = (data) => {
  const today = new Date(data);

  // Define an array of day names
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Create an array to store the date information for the next 7 days
  const next7Days = [];

  for (let i = 0; i < 7; i++) {
    // Calculate the date for the current day in the loop
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = currentDate.getDay();

    // Get the day name based on the day of the week
    const dayName = dayNames[dayOfWeek];

    // Push the date and day name to the array
    next7Days.push(dayName);
  }

  // Display the dates and day names for the next 7 days
  return next7Days;
};

export const DifferenceBetweenTwoMonths = (date1, date2) => {
  const firstdate = new Date(date1);
  const seconddate = new Date(date2);
  const diffYear = seconddate.getFullYear() - firstdate.getFullYear();
  const diffMonth = seconddate.getMonth() - firstdate.getMonth();

  return diffYear * 12 + diffMonth;
};

export const BaseUrlConverterfromUrl = (Url) => {
  const url = Url;

  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result.split(",")[1];
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export function getCurrentMonthAndYear(value = "all") {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  if (value === "all") {
    return `${currentMonth} ${currentYear}`;
  } else {
    return `${currentYear}`;
  }
}

export const AddittionofFucntio = (num1, num2) => {
  return Number(num1) + Number(num2);
};

export const isPrivateMode = async () => {
  return new Promise(function detect(resolve) {
    var yes = function () {
      resolve(true);
    }; // is in private mode
    var not = function () {
      resolve(false);
    }; // not in private mode

    function detectChromeOpera() {
      // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
      var isChromeOpera =
        /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) &&
        navigator.storage &&
        navigator.storage.estimate;
      if (isChromeOpera) {
        navigator.storage.estimate().then(function (data) {
          return data.quota < 120000000 ? yes() : not();
        });
      }
      return !!isChromeOpera;
    }

    function detectFirefox() {
      var isMozillaFirefox = "MozAppearance" in document.documentElement.style;
      if (isMozillaFirefox) {
        if (indexedDB == null) yes();
        else {
          var db = indexedDB.open("inPrivate");
          db.onsuccess = not;
          db.onerror = yes;
        }
      }
      return isMozillaFirefox;
    }

    function detectSafari() {
      var isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/);
      if (isSafari) {
        var testLocalStorage = function () {
          try {
            if (localStorage.length) not();
            else {
              localStorage.setItem("inPrivate", "0");
              localStorage.removeItem("inPrivate");
              not();
            }
          } catch (_) {
            // Safari only enables cookie in private mode
            // if cookie is disabled, then all client side storage is disabled
            // if all client side storage is disabled, then there is no point
            // in using private mode
            navigator.cookieEnabled ? yes() : not();
          }
          return true;
        };

        var version = parseInt(isSafari[1], 10);
        if (version < 11) return testLocalStorage();
        try {
          window.openDatabase(null, null, null, null);
          not();
        } catch (_) {
          yes();
        }
      }
      return !!isSafari;
    }

    function detectEdgeIE10() {
      var isEdgeIE10 =
        !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
      if (isEdgeIE10) yes();
      return !!isEdgeIE10;
    }

    // when a browser is detected, it runs tests for that browser
    // and skips pointless testing for other browsers.
    if (detectChromeOpera()) return;
    if (detectFirefox()) return;
    if (detectSafari()) return;
    if (detectEdgeIE10()) return;

    // default navigation mode
    return not();
  });
};

export const emaillidentifier = (value) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return value?.match(validRegex);
};
export const FormatphoneNumberDesing = (phonenumber) => {
  const resultphonenumber = parsePhoneNumber(phonenumber ?? "");
  if (Object?.keys(resultphonenumber ?? {})?.length > 0) {
    let countrycleanphone = resultphonenumber?.nationalNumber;

    const formatttedphonenumber = `+${
      resultphonenumber?.countryCallingCode
    }.${countrycleanphone.slice(0, 3)}.${countrycleanphone.slice(
      3,
      6
    )}.${countrycleanphone.slice(6)}`;

    return formatttedphonenumber;
  } else {
    return phonenumber;
  }
};

export const getcountrytelephoniccode = (value) => {
  if (value === "ca") {
    return "+1";
  } else if (value === "in") {
    return "+91";
  } else if (value === "ru") {
    return "+7";
  } else if (value === "ae") {
    return "+971";
  } else if (value === "us") {
    return "+1";
  } else if (value === "gb") {
    return "+44";
  }
};

export const removephoneCodeFromPhone = (parentData, substringToRemove) => {
  if (parentData.startsWith(substringToRemove)) {
    return parentData.slice(substringToRemove.length);
  } else {
    return parentData;
  }
};

export const getCountryFromTelephonicCode = (telephonicCode) => {
  switch (telephonicCode) {
    case "+1":
      return "ca";
    case "+91":
      return "in";
    case "+7":
      return "ru";
    case "+971":
      return "ar";
    case "+44":
      return "gb";
    default:
      return null; // Handle the case where the telephonic code is not recognized
  }
};

export const IsPrevorNextWeekdate = (date, Externalclass) => {
  let date1 = moment(new Date()).startOf("day");
  let date2 = moment(date).startOf("day");

  // Calculate the difference in days
  let differenceInDays = date2.diff(date1, "days");
  let label =
    differenceInDays === 0
      ? "Today"
      : differenceInDays === 1
      ? "Tomorrow"
      : differenceInDays === -1
      ? "Yesterday"
      : null;

  // return Math.abs(differenceInDays);
  if (Math.abs(differenceInDays) < 7) {
    return (
      <div>
        <Tooltip
          title={`${moment(date).format("MMM DD YYYY")}`}
          placement="bottom"
        >
          <span className={`cursor-pointer ${Externalclass}`}>
            {label || moment(date).format("dddd")}
          </span>
        </Tooltip>
      </div>
    );
  } else {
    return moment(date).format("dddd, MMM DD YYYY");
  }
};
