import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllGoalApi, getGoalForComparionApi } from "../../Api/Services";
import { toast } from "react-toastify";

export const getAllGoalReducers = createAsyncThunk(
  "report/getAllGoalApi",
  async (data) => {
    try {
      const result = await getAllGoalApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getGoalComparionReducer = createAsyncThunk(
  "report/getGoalComparionReducer",
  async (data) => {
    try {
      const result = await getGoalForComparionApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
const reportSlice = createSlice({
  name: "report",
  initialState: {
    GoalAllData: [],
    GoalComparionData: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGoalReducers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGoalReducers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.GoalAllData = payload;
      })
      .addCase(getAllGoalReducers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGoalComparionReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGoalComparionReducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.GoalComparionData = payload;
      })
      .addCase(getGoalComparionReducer.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reportAction } = reportSlice.actions;
export default reportSlice;
