import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllEventApi,
  getEventByUserIdApi,
  getPartcularEventAPi,
  getParticiPentsEventApi,
} from "../../Api/Services";
import { toast } from "react-toastify";
import { AuthAction } from "../Login/AuthSlice";

export const getAllCalendar = createAsyncThunk(
  "calendar/getAllCalendar",
  async () => {
    try {
      const result = await getAllEventApi();
      // return result.res;
      return [];
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getParticularEvent = createAsyncThunk(
  "calendar/getParticularEvent",
  async (data) => {
    try {
      const result = await getPartcularEventAPi(data);
      return result?.res?.data && result?.res?.data[0];
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getEventByUserId = createAsyncThunk(
  "calendar/getEventByUserId",
  async (data, { dispatch }) => {
    try {
      const result = await getEventByUserIdApi(data);
      dispatch(AuthAction.handleIsLoggedInIsMicroSoftAuthenticated(true));
      return result?.res;
    } catch (error) {
      if (error === "microsoftauthenitcationfailed") {
        // const result = await OutLookloginService();
        dispatch(AuthAction.handleIsLoggedInIsMicroSoftAuthenticated(false));
      }
      toast.error(error.message);
    }
  }
);
export const getParticipentsEvent = createAsyncThunk(
  "calendar/getParticipentsEvent",
  async (data) => {
    try {
      const result = await getParticiPentsEventApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    loading: false,
    AllCalendarData: [],
    AllpartcularEvent: [],
    AllParticipents: [],
    initialeventON: "",
  },
  reducers: {
    handleinitialevent: (state, { payload }) => {
      state.initialeventON = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCalendar.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCalendar.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllCalendarData = payload;
      })
      .addCase(getAllCalendar.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getParticularEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParticularEvent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllpartcularEvent = payload;
      })
      .addCase(getParticularEvent.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getEventByUserId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventByUserId.fulfilled, (state, { payload }) => {
        state.AllCalendarData = payload;
        state.loading = false;
      })
      .addCase(getEventByUserId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getParticipentsEvent.fulfilled, (state, { payload }) => {
        state.AllParticipents = payload;
      });
  },
});

export const { handleinitialevent, CalendarAction } = calendarSlice.actions;
export default calendarSlice;
