import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllSuggestpropertiesApi,
  getChooseContactSuggestPropertiesApi,
  getSuggestlocaltiesApi,
} from "../../Api/Services";

export const GetSuggestReducer = createAsyncThunk(
  "contact/GetSuggestReducer",
  async (data) => {
    try {
      const result = await getAllSuggestpropertiesApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetSuggestlocaltiesReducer = createAsyncThunk(
  "contact/GetSuggestlocaltiesReducer",
  async (data) => {
    try {
      const result = await getSuggestlocaltiesApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetSuggestChooseContactReducer = createAsyncThunk(
  "contact/GetSuggestChooseContactReducer",
  async (data) => {
    try {
      const result = await getChooseContactSuggestPropertiesApi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
const SuggestSlice = createSlice({
  name: "suggest",
  initialState: {
    loading: false,
    disable: false,
    AllSuggestData: [],
    ChooseContactAllData: [],
    SelectedListData: [],
    suggestlocaltiesdata: [],
    isShowAddText: {},
    shareableDataList: localStorage.getItem("shareableList")
      ? JSON.parse(localStorage.getItem("shareableList"))
      : [],
  },
  reducers: {
    handleSelectListData(state, { payload }) {
      state.SelectedListData = [...state.SelectedListData, payload];
      state.isShowAddText = {
        ...state.isShowAddText,
        [payload?.listingid]: true,
      };
    },
    handleRemoveSelectListData(state, { payload }) {
      state.SelectedListData = state.SelectedListData?.filter((data) => {
        return data?.listingid !== payload?.listingid;
      });
      state.isShowAddText = {
        ...state.isShowAddText,
        [payload?.listingid]: false,
      };
    },
    handleShareProperties(state, { payload }) {
      state.shareableDataList = payload;
      localStorage.setItem("shareableList", JSON.stringify(payload));
    },
    handleClearShareableList(state) {
      state.shareableDataList = [];
      state.SelectedListData = [];
      state.isShowAddText = {};
      localStorage.removeItem("shareableList");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSuggestReducer.pending, (state) => {
        state.loading = true;
        state.disable = true;
      })
      .addCase(GetSuggestReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.AllSuggestData = action.payload;
      })
      .addCase(GetSuggestReducer.rejected, (state) => {
        state.loading = false;
        state.disable = false;
      })
      .addCase(GetSuggestChooseContactReducer.pending, (state) => {
        state.loading = true;
        state.disable = true;
      })
      .addCase(GetSuggestChooseContactReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.ChooseContactAllData = action.payload;
      })
      .addCase(GetSuggestlocaltiesReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestlocaltiesdata = action.payload;
      })
      .addCase(GetSuggestChooseContactReducer.rejected, (state) => {
        state.loading = false;
        state.disable = false;
      });
  },
});
export const {
  SugggestAction,
  handleShareProperties,
  handleSelectListData,
  handleRemoveSelectListData,
  handleClearShareableList,
} = SuggestSlice.actions;

export default SuggestSlice;
