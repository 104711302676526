import React, { Suspense, lazy, useEffect, useState } from "react";
import styles from "./login.module.css";
import viranilogo from "../../Assets/images/sidebar/Virani-IQ_01.png";
import {
  OutLookloginService,
  loginService,
  refreshTokenApi,
} from "../../Utils/Api/Services";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import { AuthAction } from "../../Utils/Store/Login/AuthSlice";
import ErrorCom from "../ErrorCom";
// import Footer from "../layout/Footer";

import LoaderButton from "../LoaderButton";

import { handletokenapicalling } from "../../Utils/Store/Common/commonSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { isAuthwithoutasyn, saveToken } from "../../Utils/Function/auth";
import { Navigate, useNavigate } from "react-router-dom";
const Appfirebasereflected = React.lazy(() =>
  import("../Pushnotification/Appfirebasereflected")
);

// import Footer from "./Footer";
const Footer = lazy(() => import("../layout/Footer"));
const Login = ({ dispatch, ...props }) => {
  const [isShowPassword, SetIsShowPassword] = useState(false);
  const [disabled, SetDisabled] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [showUserName, setShowUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  const navigate = useNavigate();
  const { AuthData } = props.auth;

  useEffect(() => {
    const _Auth = JSON.parse(localStorage.getItem("AuthData"));
    if (_Auth) {
      setShowUserName(`${_Auth?.firstName} ${_Auth?.lastName}`);
    }
  }, []);

  const handleFormValidation = () => {
    let errors = {};
    let formIsValid = true;
    const required = "This is a mandatory field";
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!userName) {
      errors["userName"] = required;
      formIsValid = false;
    }
    if (!userName?.trim().match(validRegex)) {
      errors["userName"] = "Please enter a valid email address.";
      formIsValid = false;
    }
    if (!password?.trim()) {
      errors["password"] = required;
      formIsValid = false;
    }
    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (handleFormValidation()) {
        SetDisabled(true);
        setIsLoading(true);
        const data = {
          emailAddressOrUsername: userName?.trim(),
          password: password?.trim(),
          fcmtoken: localStorage?.getItem("fmctoken"),
          isWeb: true,
        };
        const result = await loginService(data);

        if (result?.res?.data?.usertwoStep === true) {
          navigate("/otp", {
            state: "usertwoster",
          });
          return;
        }
        if (result?.res?.isSuccess) {
          SetDisabled(false);
          setIsLoading(false);

          saveToken(
            result?.res?.data?.token,
            result?.res?.data?.tokenExpiryTime
          );
          localStorage.setItem("isloggeout", false);
          dispatch(AuthAction.AuthReducer(result?.res?.data));
          dispatch(AuthAction.handleIsLoggedIn(true));
          dispatch(handletokenapicalling(true));
          localStorage.setItem("AuthData", JSON.stringify(result?.res?.data));

          const timeVal = result?.res?.data?.tokenExpiryTime - 300000;
          if (timeVal) {
            setInterval(async () => {
              const result2 = await refreshTokenApi({
                accessToken: AuthData?.token,
                refreshToken: AuthData?.refreshToken,
              });
              saveToken(result2?.res?.token, result2?.res?.tokenExpiryTime);
            }, timeVal);
          }
          // navigate("/");

          if (!result?.res?.data?.outlookLogin === false) {
            navigate("/");
          } else {
            const result = await OutLookloginService();

            toast.success(
              "This page will redirect to microsoft login for enabling calendar and email sync. Please wait."
            );
            if (result?.res) {
              setTimeout(() => {
                window.location.replace(result.res);
              }, 3000);
            }
            // setmicrosofturl(result.res);
            // setopen(true);
          }
        }
      }
    } catch (error) {
      toast.error(error.message);
      SetDisabled(false);
      setIsLoading(false);
    }
  };
  const HandleClickforgetPassword = () => {
    navigate("/ForgetPassword");
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/");
  //   }
  // }, [isLoggedIn]);
  // useEffect(() => {
  //   try {
  //     if (window.navigator.serviceWorker) {
  //       const initializeFirebase = async () => {
  //         import("firebase/messaging")
  //           .then((module) => module.onMessage)
  //           .then((onMessage) => {
  //             // Importing `generateToken` and `messaging` from "../Pushnotification/firebase"
  //             import("../Pushnotification/firebase")
  //               .then((module) => {
  //                 const { generateToken, messaging } = module;

  //                 // Call the generateToken function after importing it
  //                 generateToken();

  //                 // Use the onMessage function
  //                 onMessage(messaging, (payload) => {
  //                   toast.info(payload?.notification?.title);
  //                 });
  //               })
  //               .catch((error) => {
  //                 console.error("Error importing Firebase module:", error);
  //               });
  //           })
  //           .catch((error) => {
  //             console.error(
  //               "Error importing Firebase messaging module:",
  //               error
  //             );
  //           });
  //       };

  //       initializeFirebase();
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, []);
  useEffect(() => {
    if (window.navigator.serviceWorker) {
      setIsServiceWorkerActive(true);
    }
  }, []);
  if (isAuthwithoutasyn()) return <Navigate to="/" replace />;

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        {/* Render the lazy-loaded component conditionally */}
        {isServiceWorkerActive && <Appfirebasereflected form={"login"} />}
      </Suspense>

      <div className={styles.maindiv}>
        <div className={styles.forms}>
          <div className={styles.formmainsub}>
            <div className={styles.loginsubcontent}>
              <img src={viranilogo} alt="img" className="img-fluid" />
              {showUserName && <h3>Welcome back, {showUserName}!</h3>}
              <h4>Enter your credentials to access your account</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className={styles.formstyleemail}>
                <h3>
                  Email Address<span className={styles.starcolorstyle}>*</span>
                </h3>
                <input
                  type="text"
                  className={styles.usenameemail}
                  name="userName"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                {error.userName && <ErrorCom error={error?.userName} />}
              </div>
              <div className={`{styles.formstyleemail} pt-4`}>
                <h3>
                  Password<span className={styles.starcolorstyle}>*</span>
                </h3>

                <div className={styles.passwordloginstyle}>
                  <input
                    type={isShowPassword ? "text" : "password"}
                    className={styles.usenameemail}
                    autoComplete={"false"}
                    autoFocus={false}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  {/* <img
                    src={`${isShowPassword ? path2 : path1}`}
                    alt="img"
                    className={`${
                      isShowPassword ? "activeimgpassword" : styles.imgpassword
                    } `}
                    onClick={(e) => {
                      e.preventDefault();
                      SetIsShowPassword(!isShowPassword);
                    }}
                  /> */}
                  <div
                    className={`text-${
                      isShowPassword ? "[#70747b]" : "[#667DD1]"
                    } cursor-pointer ${styles.imgpassword}`}
                    onClick={(e) => {
                      e.preventDefault();
                      SetIsShowPassword(!isShowPassword);
                    }}
                  >
                    {isShowPassword ? (
                      <VisibilityIcon className="logineyedesign" />
                    ) : (
                      <VisibilityOffIcon className="logineyedesign" />
                    )}
                  </div>
                </div>

                {error.password && <ErrorCom error={error?.password} />}
              </div>
              <div className={styles.formcontent}>
                <h3 onClick={HandleClickforgetPassword}>
                  Forgot your password?
                </h3>
                <br></br>
                <p className="cursor-default text-neutral-950">
                  By clicking Log in, you accept{" "}
                  <span
                    className="hover:underline cursor-pointer text-[#667DD1]"
                    onClick={() => {
                      const newTab = window.open("", "_blank");
                      newTab.location.href = "/privacy-policy";
                    }}
                  >
                    {" "}
                    VIRANI IQ Privacy Policy
                  </span>{" "}
                  and
                  <span
                    className="hover:underline cursor-pointer text-[#667DD1]"
                    onClick={() => {
                      const newTab = window.open("", "_blank");
                      newTab.location.href = "/terms-of-use";
                    }}
                  >
                    {" "}
                    Disclosure Policy
                  </span>
                </p>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={disabled}
                  className={`${styles.bgcolorbuttons} flex justify-center items-center`}
                >
                  {isLoading && <LoaderButton />}
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
const mapStateToProps = ({ auth, dispatch }, props) => ({
  auth,
  dispatch,
});

export default connect(mapStateToProps)(Login);
