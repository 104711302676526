import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addContactApi,
  getAllContactAPi,
  getAllPropertyData,
  getAllinterctionContactPreviewApi,
  getAutoCompleteTagsDataApi,
  getContactForDropDownsApi,
  getContactPreview,
  getContactPreviewPropertyApi,
  getContactPriceRange,
  getContactProperttyForDropDown,
  getContactUserForDropDown,
  getHomePageContactApi,
  getMeetingContactPreviewApi,
  getPartcularPropertyAPi,
  getTagsApi,
} from "../../Api/Services";
import { toast } from "react-toastify";
import { getCitiesData } from "../Common/commonSlice";

export const AddContactReducer = createAsyncThunk(
  "contact/AddContact",
  async (data) => {
    try {
      const result = await addContactApi(data);
      return result;
    } catch (error) {
      toast.error(error.title);
    }
  }
);
export const GetContactReducer = createAsyncThunk(
  "contact/GetContactReducer",
  async (data) => {
    try {
      const result = await getAllContactAPi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetContactPreviewReducer = createAsyncThunk(
  "contact/GetContactPreviewReducer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await getContactPreview(data);
      if (result?.res?.isSuccess) {
        if ((result.res?.data && result.res?.data[0]).cityName) {
          // dispatch(
          //   getCitiesData(
          //     (result.res?.data && result.res?.data[0]).cityName.slice(2)
          //   )
          // );
        }

        let resultdata = result?.res?.data && result?.res?.data[0];
        resultdata?.sellerProperties?.sort((a, b) => {
          if (a.contactPropertyType === 1 && b.contactPropertyType !== 1) {
            return -1; // a comes before b
          } else if (
            a.contactPropertyType !== 1 &&
            b.contactPropertyType === 1
          ) {
            return 1; // b comes before a
          } else {
            return 0; // maintain the current order
          }
        });

        // Move objects with contactPropertyType 1 to the front of the array
        const firstIndex = resultdata?.sellerProperties?.findIndex(
          (item) => item?.contactPropertyType === 1
        );
        if (firstIndex !== -1) {
          // If an object with contactPropertyType 1 exists, move it to the front
          const item = resultdata?.sellerProperties.splice(firstIndex, 1)[0];
          resultdata?.sellerProperties.unshift(item);
        } else {
          // If no object with contactPropertyType 1 exists, insert an empty array at the first index
          resultdata?.sellerProperties.unshift([]);
        }

        return result.res?.data && result.res?.data[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetContactParticularPropertyReducer = createAsyncThunk(
  "contact/GetContactParticularPropertyReducer",
  async (data) => {
    try {
      const result = await getPartcularPropertyAPi(data);
      if (result?.res?.isSuccess) {
        return result.res?.data && result.res?.data[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetTagsReducer = createAsyncThunk(
  "contact/GetTagsReducers",
  async () => {
    try {
      const result = await getTagsApi();
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getContactForDropdown = createAsyncThunk(
  "contact/getContactForDropdown",
  async () => {
    try {
      const result = await getContactForDropDownsApi();
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getContactuserForDropdown = createAsyncThunk(
  "contact/getContactuserForDropdown",
  async () => {
    try {
      const result = await getContactUserForDropDown();
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getContactPropertyForDropdown = createAsyncThunk(
  "contact/getContactPropertyForDropdown",
  async (data) => {
    try {
      const result = await getContactProperttyForDropDown(data);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }
);
export const GetPriceRangeReducer = createAsyncThunk(
  "contact/GetPriceRangeReducer",
  async () => {
    try {
      const result = await getContactPriceRange();
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetAllInterctionReducer = createAsyncThunk(
  "contact/GetAllInterctionReducer",
  async (id) => {
    try {
      const result = await getAllinterctionContactPreviewApi(id);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetMettingReducer = createAsyncThunk(
  "contact/GetMettingReducer",
  async (data) => {
    try {
      const result = await getMeetingContactPreviewApi(data);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetPropertyReducer = createAsyncThunk(
  "contact/GetPropertyReducer",
  async (data) => {
    try {
      const result = await getContactPreviewPropertyApi(data);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getHomePageContactReducer = createAsyncThunk(
  "contact/getHomePageContactReducer",
  async () => {
    try {
      const result = await getHomePageContactApi();
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getPropertyDetailsData = createAsyncThunk(
  "contact/getPropertyDetailsData",
  async (data) => {
    try {
      const result = await getAllPropertyData(data);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getAutoCOmpleteTagsData = createAsyncThunk(
  "contact/getAutoCOmpleteTagsData",
  async (data) => {
    try {
      const result = await getAutoCompleteTagsDataApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    disable: false,
    allinteractionloading: false,
    allcontactloading: false,
    AllContactData: [],
    ContactDetailsData: {},
    TagsData: [],
    AUTOCOMPLETETAGSDATA: [],
    contactForDropdown: [],
    priceRangeData: [],
    AllinterctionData: [],
    AllCommonContactInnerData: [],
    AllContactProperty: [],
    AllParticularProprty: [],
    homePageContactData: [],
    showHomePageContactData: [],
    ContactUserForDropDown: [],
    contactPropertyForDropDown: [],
    showNumberOfPages: 1,
    showpropertydata: "",
  },
  reducers: {
    handleShowHomePageContact(state, { payload }) {
      const indexOfLastPost = payload?.page * payload?.pageSize;
      const indexOfFirstPost = indexOfLastPost - payload?.pageSize;
      const contactSearchValue = payload?.contactSearchValue;
      state.showHomePageContactData = state?.homePageContactData
        ?.filter((data) => {
          if (contactSearchValue === "") {
            return data;
          } else if (
            data?.firstName
              ?.toLowerCase()
              ?.includes(contactSearchValue?.toLowerCase())
          ) {
            return data;
          } else if (
            data?.lastName
              ?.toLowerCase()
              ?.includes(contactSearchValue?.toLowerCase())
          ) {
            return data;
          } else if (
            data?.contactMethod
              ?.toLowerCase()
              ?.includes(contactSearchValue?.toLowerCase())
          ) {
            return data;
          }
        })
        ?.slice(indexOfFirstPost, indexOfLastPost);

      state.showNumberOfPages = state?.homePageContactData?.filter((data) => {
        if (contactSearchValue === "") {
          return data;
        } else if (
          data?.firstName
            ?.toLowerCase()
            ?.includes(contactSearchValue?.toLowerCase())
        ) {
          return data;
        } else if (
          data?.lastName
            ?.toLowerCase()
            ?.includes(contactSearchValue?.toLowerCase())
        ) {
          return data;
        } else if (
          data?.contactMethod
            ?.toLowerCase()
            ?.includes(contactSearchValue?.toLowerCase())
        ) {
          return data;
        }
      })?.length;
    },
    handlecontactproperydropdown(state, { payload }) {
      state.contactPropertyForDropDown = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddContactReducer.pending, (state) => {
        state.loading = true;
        state.disable = true;
      })
      .addCase(AddContactReducer.fulfilled, (state) => {
        state.loading = false;
        state.disable = false;
      })
      .addCase(AddContactReducer.rejected, (state) => {
        state.loading = false;
        state.disable = false;
      })
      .addCase(GetContactReducer.pending, (state) => {
        state.loading = true;
        state.allcontactloading = true;
      })
      .addCase(GetContactReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.allcontactloading = false;
        state.AllContactData = action.payload;
      })
      .addCase(getContactuserForDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.ContactUserForDropDown = action.payload;
      })
      .addCase(getContactPropertyForDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.contactPropertyForDropDown = action.payload;
      })
      .addCase(GetContactReducer.rejected, (state) => {
        state.allcontactloading = false;

        state.loading = false;
      })
      .addCase(GetContactPreviewReducer.pending, (state) => {
        state.loading = true;
        state.ContactDetailsData = [];
      })
      .addCase(GetContactPreviewReducer.fulfilled, (state, action) => {
        state.ContactDetailsData = action.payload;
        state.loading = false;
      })
      .addCase(getPropertyDetailsData.fulfilled, (state) => {
        state.showpropertydata = "something";
      })
      .addCase(GetContactPreviewReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTagsReducer.fulfilled, (state, { payload }) => {
        state.TagsData = payload;
      })
      .addCase(getContactForDropdown.fulfilled, (state, { payload }) => {
        state.contactForDropdown = payload;
      })
      .addCase(GetAllInterctionReducer.pending, (state, { payload }) => {
        state.loading = true;
        state.AllinterctionData = [];
      })
      .addCase(GetPriceRangeReducer.fulfilled, (state, { payload }) => {
        state.AllinterctionData = payload;
      })
      .addCase(GetAllInterctionReducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllinterctionData = payload;
      })
      .addCase(GetAllInterctionReducer.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(GetMettingReducer.pending, (state) => {
        state.allinteractionloading = true;
        state.AllCommonContactInnerData = [];
      })
      .addCase(GetMettingReducer.fulfilled, (state, { payload }) => {
        state.allinteractionloading = false;
        state.AllCommonContactInnerData = payload;
      })
      .addCase(GetMettingReducer.rejected, (state) => {
        state.allinteractionloading = false;
        state.AllCommonContactInnerData = [];
      })
      .addCase(GetPropertyReducer.fulfilled, (state, { payload }) => {
        state.AllContactProperty = payload;
      })
      .addCase(getHomePageContactReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomePageContactReducer.fulfilled, (state, { payload }) => {
        state.homePageContactData = payload;
        state.showNumberOfPages = payload?.length;
        state.loading = false;
      })
      .addCase(getHomePageContactReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetContactParticularPropertyReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetContactParticularPropertyReducer.fulfilled,
        (state, action) => {
          state.loading = false;
          state.AllParticularProprty = action.payload;
        }
      )
      .addCase(GetContactParticularPropertyReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAutoCOmpleteTagsData.fulfilled, (state, { payload }) => {
        state.AUTOCOMPLETETAGSDATA = payload;
      });
  },
});

export const {
  contactAction,
  handleShowHomePageContact,
  handlecontactproperydropdown,
} = contactSlice.actions;

export default contactSlice;
