import { createSlice } from "@reduxjs/toolkit";

const _authLocal = localStorage.getItem("AuthData");

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    AuthData: _authLocal ? JSON.parse(_authLocal) : {},
    ServerResponsed: false,
    isLoggedIn: false,
    IsMicroSoftAuthenticated: true,
  },
  reducers: {
    AuthReducer(state, action) {
      state.AuthData = action.payload;
    },
    ServerRespondedReducer(state, action) {
      state.ServerResponsed = true;
    },
    handleIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    handleIsLoggedInIsMicroSoftAuthenticated(state, action) {

      state.IsMicroSoftAuthenticated = action.payload;
    },
  },
});

export const AuthAction = AuthSlice.actions;

export default AuthSlice;
