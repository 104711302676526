import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AnnouementUpdateApi,
  AreaUpdateApi,
  GetAllServicesApi,
  SubAreaUpdateApi,
  getActiveAnnouncementToday,
  getActiveViewedAnnouncementToday,
  getAllAreaDataApi,
  getAllCountriesApi,
  getAllDataAnnoucemntApi,
  getAllSubreaDataApi,
  getAllTagsDataApi,
  getAreaUpdateApi,
  getCityAllDataApi,
  getCityUpdateApi,
  getCountryUpdateApi,
  getRegionAllDataApi,
  getRegionUpdateApi,
  getStateAllDataApi,
  getStateUpdateApi,
  getTagUpdateApi,
  searchAllAreaDataApi,
  searchAllSubreaDataApi,
  searchCityAllDataApi,
  searchRegionAllDataApi,
  searchStateAllDataApisuggest,
  serviceStateUpdate,
} from "../../Api/Services";
import { toast } from "react-toastify";

export const getAllServices = createAsyncThunk(
  "master/getAllServices",
  async (data) => {
    try {
      const result = await GetAllServicesApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const serviceStatusUpdate = createAsyncThunk(
  "master/serviceStatusUpdate",
  async (id) => {
    try {
      const result = await serviceStateUpdate(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const CountryStatusUpdate = createAsyncThunk(
  "master/CountryStatusUpdate",
  async (id) => {
    try {
      const result = await getCountryUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const getCountriesApi = createAsyncThunk(
  "master/getCountriesApi",
  async (data) => {
    try {
      const result = await getAllCountriesApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const getAllAnnoucementApi = createAsyncThunk(
  "master/getAllAnnoucementApi",
  async (data) => {
    try {
      const result = await getAllDataAnnoucemntApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const getAllDataStateApi = createAsyncThunk(
  "master/getAllDataStateApi",
  async (data) => {
    try {
      const result = await getStateAllDataApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const searchAllDataStateApi = createAsyncThunk(
  "master/searchAllDataStateApi",
  async (data) => {
    try {
      const result = await searchStateAllDataApisuggest(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const getAllDataCityApi = createAsyncThunk(
  "master/getAllDataCityApi",
  async (data) => {
    try {
      const result = await getCityAllDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const searchAllDataCityApi = createAsyncThunk(
  "master/searchAllDataCityApi",
  async (data) => {
    try {
      const result = await searchCityAllDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const getAllDataRegionApi = createAsyncThunk(
  "master/getAllDataRegionApi",
  async (data) => {
    try {
      const result = await getRegionAllDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const searchAllDataRegionApi = createAsyncThunk(
  "master/searchAllDataRegionApi",
  async (data) => {
    try {
      const result = await searchRegionAllDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const UpdatStateApi = createAsyncThunk(
  "master/UpdatStateApi",

  async (id) => {
    try {
      const result = await getStateUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const TagsAllDataApi = createAsyncThunk(
  "master/TagsAllDataApi",
  async (data) => {
    try {
      const result = await getAllTagsDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const TagsAllDataSearchApiApi = createAsyncThunk(
  "master/TagsAllDataSearchApiApi",
  async (data) => {
    try {
      const result = await getAllTagsDataApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const UpdatCityApi = createAsyncThunk(
  "master/UpdatCityApi",
  async (id) => {
    try {
      const result = await getCityUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const UpdateRegionApi = createAsyncThunk(
  "master/UpdateRegionApi",
  async (id) => {
    try {
      const result = await getRegionUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const UpdatTagApi = createAsyncThunk(
  "master/UpdatTagApi",
  async (id) => {
    try {
      const result = await getTagUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const UpdatSubAreaApi = createAsyncThunk(
  "master/UpdatSubAreaApi",
  async (id) => {
    try {
      const result = await SubAreaUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const UpdatAnnoucementApi = createAsyncThunk(
  "master/UpdatAnnoucementApi",
  async (id) => {
    try {
      const result = await AnnouementUpdateApi(id);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const getAreaDataApi = createAsyncThunk(
  "master/getAreaDataApi",
  async (data) => {
    try {
      const result = await getAllAreaDataApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const searchAreaDataApi = createAsyncThunk(
  "master/searchAreaDataApi",
  async (data) => {
    try {
      const result = await searchAllAreaDataApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const getSubAreaDataApi = createAsyncThunk(
  "master/getSubAreaDataApi",
  async (data) => {
    try {
      const result = await getAllSubreaDataApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const searchSubAreaDataApi = createAsyncThunk(
  "master/searchSubAreaDataApi",
  async (data) => {
    try {
      const result = await searchAllSubreaDataApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const AreaupdateApi = createAsyncThunk(
  "master/AreaupdateApi",
  async (data) => {
    try {
      const result = await AreaUpdateApi(data);

      return result?.res;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const getActiveAnnouncementTodaySlicer = createAsyncThunk(
  "master/AreaupdateApi",
  async (data) => {
    try {
      const result = await getActiveAnnouncementToday(data);
      const result2 = await getActiveViewedAnnouncementToday(data);

      // const unreadAnnouncement = result?.res?.data.filter((item1) =>
      //   result2?.resd?.data.some((item2) => item1.id === item2.announcementId || item1.id === item2.id)
      // );
      const arr1 = result?.res?.data ?? [];
      const arr2 = result2?.res?.data ?? [];
      // const unreadAnnouncement = arr2?.message ? arr1 : arr1.filter(
      //   (item) => !arr2.some((item2) => item2.announcementId === item.id)
      // );
      const unreadAnnouncement = arr2?.message
        ? arr1
        : arr1.filter(
            (item) => !arr2.some((item2) => item2.announcementId === item.id)
          );

      unreadAnnouncement.forEach((item, index) => {
        item.index = index;
      });

      // let viewedannoucement = localStorage
      //   ?.getItem("viewannouncement")
      //   ?.split(",");
      // let allannouncement = result?.res?.data;
      // let newArr = allannouncement
      //   .filter((item) => viewedannoucement?.includes(item.id))
      //   .concat(
      //     allannouncement?.filter(
      //       (item) => !viewedannoucement?.includes(item.id)
      //     )
      //   );

      // let modifiedResult = { ...result?.res, data: newArr };

      return unreadAnnouncement ?? [];
    } catch (error) {
      console.error(error);
      // toast.error(error?.message);
    }
  }
);

const masterSlice = createSlice({
  name: "master",
  initialState: {
    ServiceAllData: [],
    CountryAllData: [],
    StateAllData: [],
    AreaAllData: [],
    CityAllData: [],
    SubAreaData: [],
    AllAnnoucementData: [],
    AcitveTodayAnnouncementData: [],
    TagsAllData: [],
    loading: false,
    AllRegions: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ServiceAllData = payload;
      })
      .addCase(getAllServices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCountriesApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountriesApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.CountryAllData = payload;
      })
      .addCase(getAllDataRegionApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDataRegionApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllRegions = payload;
      })
      .addCase(getAllDataRegionApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(searchAllDataRegionApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAllDataRegionApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllRegions = payload;
      })
      .addCase(searchAllDataRegionApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getCountriesApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllDataStateApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDataStateApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.StateAllData = payload;
      })
      .addCase(getAllDataStateApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchAllDataStateApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAllDataStateApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.StateAllData = payload;
      })
      .addCase(searchAllDataStateApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllDataCityApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDataCityApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.CityAllData = payload;
      })
      .addCase(getAllDataCityApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(searchAllDataCityApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAllDataCityApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.CityAllData = payload;
      })
      .addCase(searchAllDataCityApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(TagsAllDataApi.pending, (state) => {
        // state.loading = true;
      })
      .addCase(TagsAllDataApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.TagsAllData = payload;
      })
      .addCase(TagsAllDataApi.rejected, (state) => {
        // state.loading = false;
      }) //TagsAllDataSearchApiApi
      .addCase(TagsAllDataSearchApiApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.TagsAllData = payload;
      })
      .addCase(getAreaDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAreaDataApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AreaAllData = payload;
      })
      .addCase(getAreaDataApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(searchAreaDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAreaDataApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AreaAllData = payload;
      })
      .addCase(searchAreaDataApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getSubAreaDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubAreaDataApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SubAreaData = payload;
      })
      .addCase(getSubAreaDataApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(searchSubAreaDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchSubAreaDataApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SubAreaData = payload;
      })
      .addCase(searchSubAreaDataApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getAllAnnoucementApi.pending, (state) => {
        state.loading = false;
      })
      .addCase(getAllAnnoucementApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllAnnoucementData = payload;
      })
      .addCase(
        getActiveAnnouncementTodaySlicer.fulfilled,
        (state, { payload }) => {
          state.AcitveTodayAnnouncementData = payload;
        }
      )
      .addCase(getAllAnnoucementApi.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default masterSlice;
