import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../Assets/style/global.css";
import "../Assets/style/mediaquery.css";

import Login from "./auth/Login";
import { connect } from "react-redux";
import Loader from "./Common/Loader";
import LogRocket from "logrocket";
import { Bounce, toast } from "react-toastify";
import Microsofterror from "./auth/Errormicrosoft";
// import Layout from "./index";

const TermsAndCondition = lazy(() => import("./Policy/TermsAndCondition"));
const PrivacyPolicy = lazy(() => import("./Policy/PrivacyPolicy"));
const ForgetPassword = lazy(() => import("./ForgetPassword"));
const LoginOtp = lazy(() => import("./LoginOTP"));
const ResetPassword = lazy(() => import("./Resetpassword"));
const Layout = lazy(() => import("./index"));
const Appfirebasereflected = lazy(() =>
  import("./Pushnotification/Appfirebasereflected")
);

const App = (props) => {
  const { AuthData } = props.auth;
  const [loading] = useState(false);
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.init("phkg9p/errrortrackingvirani");

      LogRocket.identify(AuthData?.userId, {
        name: AuthData?.fullName,
        email: AuthData?.emailAddress,
      });
    }
  }, []);

  useEffect(() => {
    if (window.navigator.serviceWorker) {
      setIsServiceWorkerActive(true);
    }
  }, []);
  // toast(
  //   <div className="h-[40vh]  bg-[#f5f7fb]">
  //     <div class="bg-[#667DD1] text-start text-white py-2 px-4 font-semibold">
  //       Sync Authorization Required
  //     </div>
  //     <div className="flex justify-center">
  //       <img
  //         src="https://play-lh.googleusercontent.com/_1CV99jklLbXuun-6E7eCPR-sKKeZc602rhw_QHZz-qm7xrPdgWsJVc7NtFkkliI8No"
  //         alt="image"
  //         className="w-[20vh]  mt-[6%]"
  //       />
  //     </div>
  //     <div className="py-2 px-4 flex justify-center ">
  //       <h1 className="pt-2 c formatted_color_imp">
  //         Authentication Required: You'll be redirected to Microsoft's login
  //         page to authenticate and enable calendar sync
  //       </h1>
  //     </div>
  //   </div>,
  //   {
  //     position: "top-center",
  //     autoClose: false,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: false,
  //     progress: undefined,
  //     theme: "light",
  //     transition: Bounce,
  //     closeButton: false,
  //     style: {
  //       width: "25vw",
  //       left: "-25%",
  //     },
  //   }
  // );

  return (
    <div>
      {/* <Routes>
        {" "}
        
      </Routes> */}

      <Suspense
        fallback={
          <div className="mt-[25%]">
            <Loader />
          </div>
        }
      >
        {isServiceWorkerActive && <Appfirebasereflected />}

        {loading ? (
          <div className="mt-[25%]">
            <Loader />
          </div>
        ) : (
          <Routes>
            <Route path="/*" element={<Layout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/microsofterror" element={<Microsofterror />} />
            <Route path="/terms-of-use" element={<TermsAndCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="/otp" element={<LoginOtp />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            {/* {isLoggedIn === true ? ( */}

            {/* ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}{" "} */}
          </Routes>
        )}
      </Suspense>
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => ({
  auth,
});

export default connect(mapStateToProps)(App);
