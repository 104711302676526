import cookie from "react-cookies";
import { refreshTokenApi } from "../Api/Services";

const TOKEN = "_e_token_";

export const getExpireDate = (expireTime) => {
  var date = new Date();
  var expiry = new Date(date.getTime() + expireTime);
  // console.log(expiry)
  // var date = new Date();
  // var expireTime2 = 2 * 60 * 1000; // 2 minutes in milliseconds
  // var expiry = new Date(date.getTime() + expireTime2);

  return expiry;
};

export const saveToken = (Token, expireTime) => {
  if (Token) {
    cookie.save(TOKEN, Token, { expires: getExpireDate(expireTime) });
  }
};

export const isAuthwithoutasyn = () => {
  const token = cookie.load(TOKEN);
  if (!token || token === "") return false;
  else return true;
};

export const isAuth = async () => {
  try {
    let token = await cookie.load(TOKEN);

    if (token) {
    } else {
      if (JSON.parse(localStorage.getItem("AuthData")).token) {
        const result2 = await refreshTokenApi({
          accessToken: JSON.parse(localStorage.getItem("AuthData")).token,
          refreshToken: JSON.parse(localStorage.getItem("AuthData")).refreshToken,
        });
        if (result2?.res) {
          token = "tokenisavailable";
          saveToken(result2?.res?.token, result2?.res?.tokenExpiryTime);
        }

        const currentDate = new Date();
        localStorage.setItem("tokendata", `${token}-${currentDate}`);
      } else {
        return false;
      }
    }

    if (!token || token === "") return false;
    else return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const getToken = () => {
  return cookie.load(TOKEN);
};

export const removeToken = () => {
  cookie.remove(TOKEN);
};
