import React from "react";
import styles from "./login.module.css";
import viranilogo from "../../Assets/images/sidebar/Virani-IQ_01.png";
import { connect } from "react-redux";
import { OutLookloginService } from "../../Utils/Api/Services";
import { toast } from "react-toastify";

const Microsofterror = (props) => {
  const { AuthData } = props.auth;
  const navigateToLogin = async () => {
    try {
      const result = await OutLookloginService();

      toast.success(
        "This page will redirect to microsoft login for enabling calendar and email sync. Please wait."
      );
      if (result?.res) {
        setTimeout(() => {
          window.location.replace(result.res);
        }, 3000);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {" "}
      <div
        className={`${styles.maindiv} relative flex justify_center_imp justify-center text-center`}
      >
        <div className={styles.forms}>
          <div className={styles.formmainsub}>
            <div className={styles.loginsubcontent}>
              <img src={viranilogo} alt="img" className="img-fluid" />
              <h4 className="text-[1.2rem] mt-[5px] mb-[5px] text-left">
                The entered email on microsoft outlook login does not match with
                the email used on ViraniIQ. Please use {AuthData?.emailAddress}{" "}
                for microsoft outlook linking.
              </h4>
            </div>
            <div>
              <button
                type="submit"
                onClick={navigateToLogin}
                className={`${styles.bgcolorbuttons} flex justify-center items-center`}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, dispatch }, props) => ({
  auth,
  dispatch,
});

export default connect(mapStateToProps)(Microsofterror);
