import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllUsersApi,
  getTeamDataApi,
  getTeamsFroDropDownApi,
  getUerDetailsApi,
  getUserAdmintypeApi,
  getUserFroDropDownApi,
  getrelationFroDropDownApi,
  sendUserCredentialApi,
  userStateUpdate,
} from "../../Api/Services";
import { toast } from "react-toastify";
import { formatDate } from "../../../Components/function";

export const getUSerAdmintypeData = createAsyncThunk(
  "user/getUSerAdmintypeData",
  async (data) => {
    try {
      const result = await getUserAdmintypeApi(data);

      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getUserAllData = createAsyncThunk(
  "user/getUserAllData",
  async (data) => {
    try {
      const result = await getAllUsersApi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getTeamAllData = createAsyncThunk(
  "user/getTeamAllData",
  async (data) => {
    try {
      const result = await getTeamDataApi(data);
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getUserforDropDown = createAsyncThunk(
  "user/getUserforDropDown",
  async (data) => {
    try {
      const result = await getUserFroDropDownApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getTeamsforDropDown = createAsyncThunk(
  "user/getTeamsforDropDown",
  async (data) => {
    try {
      const result = await getTeamsFroDropDownApi(data);
      return result.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getRelationtocontact = createAsyncThunk(
  "user/getRelationtocontact",
  async () => {
    try {
      const result = await getrelationFroDropDownApi();

      return result.res?.relation;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const updateUserSatatus = createAsyncThunk(
  "user/updateUserSatatus",
  async (id) => {
    try {
      const result = await userStateUpdate(id);
      return result.res;
    } catch (error) {
      toast.error(error.title);
    }
  }
);

export const sendUserCredential = createAsyncThunk(
  "user/updateUserSatatus",
  async (id) => {
    try {
      const result = await sendUserCredentialApi(id);
      return result.res;
    } catch (error) {
      toast.error(error.title);
    }
  }
);
export const GetAllUserDetailsReducer = createAsyncThunk(
  "contact/GetAllUserDetailsReducer",
  async (id) => {
    try {
      const result = await getUerDetailsApi(id);
      if (result?.res?.isSuccess) {
        return result.res?.data;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    AllUserData: [],
    AllTeamsData: [],
    TeamsFOrDropDown: [],
    userAdmindropdown: [],
    loading: false,
    AllActiveUserData: [],
    userForDropDown: [],
    TeamDataForDropDown: [],
    showAllUsers: [],
    AllDetailsUserData: {},
    isEditInviteUser: false,
    pertiCulartUserData: {},
    relationdropdown: [],
    teamloading: false,
  },
  reducers: {
    handleUserFilter(state, { payload }) {
      state.showAllUsers = state.AllUserData.filter((data) => {
        if (payload === "") {
          return data;
        } else if (data?.userTypeName === payload) {
          return data;
        } else if (data?.userStatus === payload) {
          return data;
        } else if (data?.designation === payload) {
          return data;
        } else if (formatDate(data?.loginActivity[0]) === payload) {
          return data;
        } else if (payload == undefined) {
          return data;
        } else if (payload == "1970-1-1") {
          return data;
        }
      });
    },
    handleUserSearch(state, { payload }) {
      state.showAllUsers = state.AllUserData.filter((data) => {
        if (payload === "") {
          return data;
        } else if (data?.name?.toLowerCase().includes(payload.toLowerCase())) {
          return data;
        } else if (
          data?.firstName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.lastName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.userName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.stateName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.countryName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.userTypeName?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        }
        // else if(data?.genderName?.toLowerCase().includes(payload.toLowerCase())){
        //     return data;
        // }
        else if (
          data?.designation?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        } else if (
          data?.department?.toLowerCase().includes(payload.toLowerCase())
        ) {
          return data;
        }
      });
    },
    HandleCloseUserEditInvite(state) {
      state.isEditInviteUser = false;
      state.pertiCulartUserData = {};
    },
    HandleOpenUserEditInvite(state, { payload }) {
      state.isEditInviteUser = true;
      state.pertiCulartUserData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAllData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.AllUserData = action.payload;
      })
      .addCase(getUserAllData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTeamAllData.pending, (state) => {
        state.teamloading = true;
      })
      .addCase(getTeamAllData.fulfilled, (state, action) => {
        state.teamloading = false;
        state.AllTeamsData = action.payload;
      })
      .addCase(getTeamAllData.rejected, (state) => {
        state.teamloading = false;
      })
      .addCase(getUserforDropDown.fulfilled, (state, { payload }) => {
        state.userForDropDown = payload;
      })
      .addCase(getTeamsforDropDown.fulfilled, (state, { payload }) => {
        state.TeamsFOrDropDown = payload;
      })
      .addCase(getRelationtocontact.fulfilled, (state, { payload }) => {
        state.relationdropdown = payload;
      })
      .addCase(getUSerAdmintypeData.fulfilled, (state, action) => {
        state.userAdmindropdown = action.payload;
      })
      .addCase(GetAllUserDetailsReducer.pending, (state) => {
        state.loading = true;
        state.disable = true;
      })
      .addCase(GetAllUserDetailsReducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.AllDetailsUserData = payload && payload[0];
      })
      .addCase(GetAllUserDetailsReducer.rejected, (state) => {
        state.loading = false;
        state.disable = false;
      });
  },
});

export const {
  UserAction,
  handleUserFilter,
  handleUserSearch,
  HandleOpenUserEditInvite,
  HandleCloseUserEditInvite,
} = userSlice.actions;
export default userSlice;
