import axios from "axios";
import { getToken, removeToken, saveToken } from "./auth";
import { Server500Handler, refreshTokenApi } from "../Api/Services";
import { toast } from "react-toastify";
const Axios = axios.create();

// Throttle the display of 500 error toast
let isServerErrorToastDisplayed = false;

Axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  },
  (Err) => {
    return Promise.reject(Err);
  }
);

Axios.interceptors.response.use(
  (res) => {
    if (res.status === 500) {
      Server500Handler();
    }
    return res;
  },
  async (Err) => {
    if (Err.response && Err.response.status === 401) {
      const AuthData = JSON.parse(localStorage.getItem("AuthData"));
      const result2 = await refreshTokenApi({
        accessToken: AuthData?.token,
        refreshToken: AuthData?.refreshToken,
      });

      saveToken(result2?.res?.token, result2?.res?.tokenExpiryTime);
      // removeToken();
      // window.location.href = window.location.origin + '/login';
    }

    if (Err.response && Err.response.status === 403) {
      // if (Err.response?.config?.url === "User/RefreshToken") {
      removeToken();
      localStorage.setItem("logouthappended because of token", new Date());
      window.location.href = window.location.origin + "/login";
      // }
    }
    if (Err?.response && Err?.response?.status === 500) {
      if (!isServerErrorToastDisplayed) {
        isServerErrorToastDisplayed = true;
        // toast.error("Internal Server Error");
        Server500Handler();
        setTimeout(() => {
          isServerErrorToastDisplayed = false;
        }, 5000); // Throttle duration, adjust as needed
      }
    }

    return Promise.reject(Err);
  }
);

export const handelError = (Err) => {
  let errMsg = "Something Went Wrong";
  if (Err && Err.message) errMsg = Err.message;
  return {
    error: true,
    message: errMsg,
  };
};

export default Axios;
