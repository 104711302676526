export const LOGIN = "User/SignIn";
export const OUTLOOK_LOGIN = "Outlook/Auth";
export const MLS_API_URL =
  "https://ravi.techtonic.asia/search.php?L_DisplayID=R2830359";

export const ALL_USERS = "Users";
export const INVITE_USER = "User/SignUp";
export const ALL_COUNTRY = "Country";
export const ALL_STATE = "State";
export const ALL_CITY = "City";
export const COMMON_DROPDOWN_OPTIONS = "Contact/GetAddContactDropDowns";
export const ALL_TIMEZONES_OPTIONS = "Users/TimeZones";
export const ADD_CONTACT = "Contact/AddContact";
export const SEND_INVITE_TO_CONTACT = "Contact/InviteSingleCustomer";
export const ALL_CONTACT = "Contact";
export const ALL_TASK = "Task/GetALLTask";
export const ADD_TASK = "Task/AddTask";
export const UPDATE_TASK = "Task/UpdateTask/id";
export const UPDATE_TASK_ALL_FIELD = "Task/UpdateTaskWithValue/id";
export const ADD_LISTING = "TranscationControllers";
export const ADD_LISTING_PENDING = "TranscationControllers/AddListingPending";

export const ADD_LISTING_AS_DRAFT = "TranscationControllers/AddListingDraft";
export const EDIT_LISTING = "TranscationControllers/id";
export const EDIT_LISTING_DRAFT_SECTION =
  "TranscationControllers/UpdateDraftListing";
export const EDIT_CONTACT = "Contact/id";
export const UPDATE_CMA = "Contact/AddPropertyCMA";
export const IMPORT_CONTACT_API = "Contact/ImportContactExcel";
export const IMPORT_CONTACT_API_SECONDARY =
  "Contact/ImportContactPropertiesExcel";

export const ALL_LOCALITIES = "Locality/GelLocalities_auto";
export const ALL_LOCALITIES_FORMaster = "Locality/GelLocalitiesDetails_auto";

export const ALL_LOCALITIES2ND = "City/GetAutusuggestCities";
export const PRIMARY_SELLER_DATA = "Contact/GetAutusuggestContact";
export const ALL_CITIES = "City/GetAutusuggestCities";
export const ALL_STATE_DATA = "State/GetAutusuggesstStates";

export const SUGGEST_LOCALITIES = "Locality/GetLocalities";
export const USER_FOR_DROPDOWN = "Contact/UserForDropdown";
export const TEAMS_FOR_DROPDOWN = "MasterUser/GetTeamForDropdown";

export const RELATION_FOR_DROPDOWN = "Contact/GetAddContactDropDowns ";
export const GET_CITY_AUTO = "Locality/GetCity_auto";
export const GET_ALL_EVENTS = "Event/GetALLEvents";
export const USERS_UPDATE_STATUS = "Users/update";
export const SEND_USER_CREDENTIAL_ROUTE = "Users/passwordlater";

export const USERS_EDIT = "Users/id";
export const TRANSACTION_CONFIRMATION =
  "TranscationControllers/Updatedatalistingdataactivetopending";
export const ALL_TRANSACTION = "TranscationControllers/GetAllListing";
export const GET_CONTACT_PREVIEW = "Contact/GetContactDetailsByID";
export const GET_TAGS = "Tags/GetContactTagsForDropDown";
export const ADD_EVENT = "Event/AddEvent";
export const ADD_EVENT_NOTIFICATION = "Notification/sendNotification";
export const CONTACT_FOR_DROPDOWN = "Contact/ContactForDropdown";
export const TRANSACTION_CLOSING = "TranscationControllers/GetlistingDropDowns";
export const ADD_CLOSING = "TranscationControllers/AddClosing";
export const TRANSACTION_VIEW = "TranscationControllers/trascationviewdata";
export const TRANSACTION_VIEW_DRAFT = "TranscationControllers/GetListingDraft";

export const TASK_AND_HOME = "Task/GetCountByMonth/GetCountByMonth";
export const GET_COUNT_BY_WEEK = "Task/GetCountByWeek/GetCountByWeek";
export const UPDATE_TASK_STATUS = "Task/UpdateTaskStatus/Id";
export const GET_TASK_WEEK = "Task/GetTaskByWeek";
export const GET_TASK_DAY = "Task/GetTaskByDay";
export const GET_CONTACT_PRICERANGE = "Contact/GetPriceRange";
export const GET_AllINTERCTION_CONTACTPREVIEW = "ContactPreview";
export const UPDATE_EVENT = "Event/UpdateEvent";
export const LOG_TASK = "Task/LogTask";
export const LOG_TASK_EXTERNAL = "Task/LogExternalTask";
export const USER_DETAILS = "Users/UderId";
export const GET_PROPERTY =
  "Event/GetautoPropertyAddress/GetautoPropertyAddress?";
export const ADD_NOTE = "ContactPreview/AddNotes";
export const EDIT_NOTE = "ContactPreview/UpdateNote";
export const DELETE_NOTE = "ContactPreview/DeleteNote";
export const ADD_TASK_DROPDOWN = "Task/AddTaskDropdowns/GetAddTaskDropdowns";
export const GET_TRANSACTION_DROPDOWNS =
  "TranscationControllers/GetAddTaskDropdowns";
export const TRANSACTION_ADD_NOTE = "TranscationControllers/AddNotes";
export const TRANSACTION_ADD_DOCUMENT = "TranscationControllers/Adddocument";
// export const ADD_POST_MAIL = "ContactPreview/PostMail";  //Outlook/OutlookEmail
export const ADD_POST_MAIL = "Outlook/OutlookEmail";
export const ADD_POST_MAIL_UPDATED = "ContactPreview/PostMail";
export const ADD_PROPERTY = "ContactPreview/AddProperty";
export const CONTACTPREVIEW_ADDPROPERTY = "ContactPreview";
export const TASK_DELETE = "Task/DeleteTask/id";
export const EVENT_DELETE = "Event/DeleteEvent/ParentEventId";
export const HOME_PAGE_CONTACT = "Contact/HomePage";
export const COMMON_ADD_USER_DROPDOWNS = "Users/GetAddUserDropDowns";
export const HOME_PAGE_CONTACT_DATA = "Contact/AutoSuggestContacts";
export const SUPPORT_DROPDOWN_API = "Users/GetAddUserDropDowns";
export const GET_SUGGESTEDUSERS = "Users/GetSuggestedUsers";
export const ADD_CREATEMEETING = "ContactPreview/CreateMeeting";
export const REPORTS_ADD_GOAL = "Reports";

export const REPORTS_LOCK_UNLOCK = "Reports/UpdateGoalStatus";
export const GET_GOAL_COUNT = "Reports/GetGoalCount";
export const GET_MY_REPORT = "Reports/GetAgentAnualAllSectionReport";
export const GET_TEAM_REPORT_DATA =
  "Reports/GetAgentMonthlyCMAReportwithPropertyDetails";
export const GET_MYREPORT_TASK_PERFORMANCEDATA = "Reports/GetAgentReport";
export const GET_MYREPORT_TASK_PERFORMANCEDATAYEARWISE =
  "Reports/GetAgentAnnualReport";
export const GET_EVENTBYID = "Event/GetEventById/GetEventById";
export const GET_PROPERTYBYID = "ContactPreview/GetPropertyById";
export const EDIT_PROPERTY = "ContactPreview/UpdateProperty";
export const EDIT_TRANSACTION_NOTE = "TranscationControllers/NotesUpdate";
export const PARCTICULAR_PROPERTYBYID = "ContactPreview/GetPropertyById";
export const All_SUGGEST_PROPERTIES =
  "SuggestProperties/GetAllSuggestedProperties";
export const TRANSACTION_DELETE_NOTES = "TranscationControllers/DeleteNotes";
export const DELETE_EMAIL = "ContactPreview/DeleteEmail";
export const SEND_COMPOSE_API = "Template/SendSms";

export const ADD_CONTACT_REPORT = "ContactPreview/AddReport";
export const ADD_BROCHURE_DOCUEMEN_FORMAT = "Template/AddSendBrochureTamplate";
export const CONTACT_DELETE_PROPERTY = "ContactPreview/DeleteProperty";
export const RESET_PASSWORD = "User/Reset-Password";
export const REFRESH_TOKEN = "User/RefreshToken";
export const CHOOSE_CONTACT = "SuggestProperties/ChoseContact";
export const FORGOT_PASSWORD = "User/ForgotPassword";
export const OTP = "User/Otpverification";
export const OTPLOGIN = "User/LoginOtpverification";
export const RESET_PASSWORD_LOGIN = "User/ResetPasswordafterotpmatch";
export const EDIT_ADDCLOSING = "TranscationControllers/UpdateClosing";
export const CONTACT_USERFOR_DROPDOWN = "Contact/ContactUserForDropdown";
export const CONTACT_PROPERTY_FOR_DROPDOWN =
  "Event/GetContactProperties/GetContactProperties";
export const GETEVENT_BY_USERID = "Event/GetEventByUserId/GetEventByUserId";
export const ADD_COUNTRY = "Country";
export const ADD_STATE = "State/AddState";
export const GET_STATE_BY_ID = "State/GetStateById";
export const UPDATE_STATE_API = "State/UpdateState";
export const ACTIVE_LISTING_EVENT_PROPERTY =
  "Event/GetActiveListing/GetActiveListing";

export const ADD_CITY = "City/AddCity";
export const ADD_REGION = "Region/AddRegion";
export const ADD_SERVICES = "CustomerService/AddService";
export const ALL_SERVICES = "CustomerService/GetAllServices";
export const UPDATE_SERVICE = "CustomerService/UpdateService";

export const Get_All_Country = "Country";
export const ADD_SUGGEST = "SuggestProperties/AddSuggest";
export const GET_CLOSING_TRANSACTION = "TranscationControllers/Getclosingdata";
export const transactionActiveStatusChange =
  "TranscationControllers/Smallupdateapi";
export const TRANSACTION_PENDING_CHANGE_STATUS =
  "TranscationControllers/Smallupdateapiclose";
export const GET_PARTICIPENTS = "Event/GetParticipents";
export const GET_CLOSINGVIEWDATA = "TranscationControllers/Closingviewdata";
export const GET_NOTIFICATION = "Notification/GetNotification";
export const GET_NOTIFICATION_READ = "Notification/GetNotificationStatus";
export const MARK_NOTIFICATION = "Event/UpdateEventStatus/Id";
export const MARK_NOTIFICATION_2ND = "Notification/AddNotificationStatus";
export const GET_NOTIFICATION2nd = "Event/GetNotification/GetNotification";
export const GET_ASSIGNED_DROPDOWN = "Contact/GetAssignedUserByRoll";
export const TRANSACTION_DELETE_IMAGE = "TranscationControllers/DeleteImage";
export const GET_USER_ADMINTYPE = "Users/admintype";
export const ADD_EMAIL_TEMPLATE = "Template/AddEmailTemplate";
export const ADD_SMS_TEMPLATE = "Template/AddSmsTemplate";
export const GET_SMS_TEMPLATE = "Template/GetSmsTemplates";
export const ADD_SMS_TEMPFOLDER = "Template/AddSmsTemplateFolder";
export const ADD_EMAIL_TEMPFOLDER = "Template/AddEmailTemplateFolder";
export const GET_SMS_TEMPFOLDER = "Template/GetSmsTemplateFolder";
export const GET_EMAIL_TEMPFOLDER = "Template/GetEmailTemplateFolder";
export const Get_BRACKETREPLACE_FROM_BACKEND = "Contact/Emaildata";
export const GET_EMAIL_TEMPLATE = "Template/GetEmailTemplates";
export const GET_MARKETING_DROPDOWN = "Template/GetAddTemplateDropdowns";
export const ADD_SENDEMAIL_TEMPLATE = "Template/AddSendEmailTamplate";
export const ADD_SENDSMS_TEMPLATE = "Template/AddSendSmsTamplate";
export const DELETE_EMAIL_TEMPLATE = "Template/DeleteEmailTemplate";
export const DELETE_DIRECTMAILERTEMPLATE_TEMPLATE =
  "Template/DeleteMailerTemplate";
export const DELETE_BROCHURE_TEMPLATE = "Template/DeleteBrochureTemplate";
export const ADD_MAILER_TEMPLATE = "Template/AddMailerTemplateFolder";
export const GET_MAILER_TEMPLATE = "Template/GetMailerTemplateFolder";
export const EDIT_EMAILTEMPLATE = "Template/UpdateEmailTemplates";
export const EDIT_SMS_TEMPLATE = "Template/SmsTemplateUpdate";
export const GET_HOME_COMPAREDTO = "Task/GetTaskCountBy/GetTaskCount";
export const ADD_BROCHURE_FOLDER = "Template/AddBrochureTemplateFolder";
export const GET_BROCHURE_FOLDER = "Template/GetBrochureTemplateFolder";
export const DELETE_SMS_TEMPLATES = "Template/DeleteSmsTemplate";
export const GOALFOR_COMPARISION = "Reports/GetGoalForComparision";
export const ADD_SEQUENCE = "Sequence/AddSequence";
export const GET_SEQUENCE = "Sequence/GetAllSequences";
export const ADD_SEQUENCE_SETTING = "Sequence/AddSequenceSetting";
export const ADD_SEQUENCE_SHARE = "Sequence/AddSequenceShare";
export const GET_PERTICULARSEQUANCE = "Sequence/GetSequenceByid";
export const GET_PERTICULAR_SEQUANCE_TASK = "Task/GetTaskByid/GetTaskByid";
export const SEQUANCE_STATUS_UPDATE = "Sequence/SequencStatusupdate";
export const SEQUANCE_UPDATE = "Sequence/SequenceUpdate";
export const SEQUANCE_SETTING_UPDATE = "Sequence/SequenceSettingUpdate";
export const GETLISTING_MLSAPI = "TranscationControllers/listingMLSAPI";
export const GETMETA_DATA_MLS = "search?searchType=Property";
export const GET_EXPORT_USER_DATA = "Users/export-to-excel";
export const ADDBROCHER_TEMPLATE_DESIGN = "Template/AddSendBrochureTamplate";
export const GETBROCHER_TEMPLATE_DESIGN = "Template/GetBrochureTemplates";
export const OUT_LOOK_AUTH = "Outlook/Auth";
export const OUT_CREATE_EVENT = "Outlook/CreateEvent";
export const OUT_UPDATE_EVENT = "Outlook/UpdateOutlookevent";
export const TWO_STEP_UPDATE = "Users/TwoStepupdate";
export const ADD_DIRECT_MAILER_TEMPLATE = "Template/AddSendMailerTamplate";
export const ADD_DIRECT_MAILER_TEMPLATE_UPDATED =
  "Template/AddSendMailerTamplate";
export const UPDATE_DIRECT_MAILER_TEMPLATE = "Template/MailerTemplateUpdate";
export const GET_DIRECT_MAILER_TEMPLATE = "Template/GetEmailerTemplates";
export const SIGNAL_R_CONNCETION = "notificationHub";
export const AUTO_SUGGESTCOUNTRY = "Country/GetAutusuggesstCountries";
export const SERVICESTATUSUPDATE = "CustomerService/UpdateServiceStatus";
export const GET_ALLCOUNTRIES = "Country/GetAllCountries";
export const COUNTRY_UPDATE = "Country/UpdateCountryStatus";
export const AUTO_SUGGESTSTATE = "State/GetAutusuggesstStates";
export const AUTO_SUGGESTREGION = "Region/GetAutosuggestRegion";
export const VIEWED_ANNOUNCEMENT_TODAY =
  "Announcement/GetNotificationStatus/GetReadAnnouncements";
export const ACTIVE_ANNOUNCEMENT_TODAY =
  "Announcement/GetCurrentAnnouncements/GetCurrentAnnouncements";
export const GET_ALLSTATEDATA = "State/GetAllStates";
export const SEARCH_ALLSTATEDATA = "State/GetSearchStateByName";
export const UPDATE_STATE = "State/UpdateStateStatus";
export const GEt_ALLCITYDATA = "City/GetAllCities";
export const SEARCH_ALLCITYDATA = "City/GetSearchCityByName";
export const GEt_ALLREGIONDATA = "Region/GetAllRegion";
export const SEARCH_ALLREGIONDATA = "Region/GetSearchRegionByName";
export const UPDATE_CITYSTATUS = "City/UpdateCityStatus";
export const UPDATE_REGIONSTATUS = "Region/UpdateRegionStatus";
export const AUTO_SUGGESTCITYAPI = "City/GetAutusuggestCities";
export const ADD_TAG = "Tags";
export const UPDATE_TAG_ROUTE = "Tags/UpdateTag";
export const GET_ALLTAGS = "Tags/GetAllTags";
export const UPDATE_TAG = "Tags/UpdateTagStatus";
export const ADD_AREA = "Locality/AddLocalities/AddLocality";
export const GET_ALL_AREA = "Locality/GetAllLocalities/GetAllLocalities";
export const SEARCH_ALL_AREA = "Locality/GelSearchLocalityByName";

export const UPDATE_AREA = "Locality/UpdateStatus/UpdateLocalityStatus";
export const ADD_SUBAREA = "SubArea/AddSubArea/AddSubArea";
export const GET_ALL_SUBAREA = "SubArea/GetAllAubArea/GetAllAubArea";
export const SEARCH_ALL_SUBAREA =
  "SubArea/GetSearchSubAreaByName/GetSearchSubAreaByName";
export const UPDATE_SUBAREA = "SubArea/UpdateStatus/UpdateSubAreaStatus";
export const ADD_ANNOUCEMENT = "Announcement/Add/AddAnnouncement";
export const UPDATE_ANNOUCEMENT_DATA =
  "Announcement/UpdateAnnouncement/UpdateAnnouncement";

export const ADD_SUPPORT = "Users/SendSupportQueries";

export const ACCEPT_ANNOUCEMENT =
  "Announcement/MarkAcceptedAnnouncement/MarkAcceptedAnnouncement";

export const GET_ALLANNOUCEMNET =
  "Announcement/GetAllAnnouncements/GetAllAnnouncements";
export const UPDATE_ANNOUCEMENT =
  "Announcement/UpdateStatus/UpdateAnnouncementStatus";
export const RENAME_DIRECTMAILER_TEMPLATE_FOLDERNAME =
  "Template/MailerFolderUpdate";
export const RENAME_BROCHURE_TEMPLATE_FOLDERNAME =
  "Template/BrochureFolderUpdate";
export const RENAME_EMAIL_TEMPLATE_FOLDERNAME =
  "Template/UpdateEmailTemplatesInFolder";
export const RENAME_SMS_TEMPLATE_FOLDERNAME =
  "Template/UpdateSmsTemplatesInFolder  ";
export const HANDLE_NOTIFICATIONS = "Users/SetNotificationType";

export const DELETE_DIRECTMAILER_TEMPLATE_FOLDERNAME =
  "Template/DeleteDirectMailerTemplateFolder";
export const DELETE_BROCHURE_TEMPLATE_FOLDERNAME =
  "Template/DeleteBrochureTemplateFolder";
export const DELETE_EMAIL_TEMPLATE_FOLDERNAME =
  "Template/DeleteEmailTemplateFolder";
export const DELETE_SMS_TEMPLATE_FOLDERNAME =
  "Template/DeleteSmsTemplateFolder";
export const SAVE_SIGNATURE = "Users/SetUserSignature";
export const DELETE_SIGNATURE = "Users/DeleteSignature";
export const DELETE_ANNOUNCEMENT_IMAGE = "Announcement/Delete/DeleteImage";
export const GET_ANNOUNCEMENTBY_ID =
  "Announcement/GetAnnouncementById/GetAnnouncementById";
export const GET_SIGNATURE = "Users/GetSignature";
export const GET_ALL_DRAFT = "TranscationControllers/GetAllListingDraft";
export const MIGRATE_DATA_API = "TranscationControllers/DataTransfer";
export const CONFIRM_CMA_NOTIFICATION =
  "Event/SendMailPropertyCMA/SendCMAMailer";
export const MLS_DATA_API_ROUTE_NEW =
  "TranscationControllers/GetlistingdatausingmlsNo?";
export const ADD_TEAM_LEADER_DATA = "MasterUser";
export const UPDATE_TEAM_LEADER_DATA = "MasterUser/UpdateMasterUser";
export const ALL_TEAM = "MasterUser/GetAllTeam";
export const ALL_TEAM_SEARCH = "MasterUser/GetSearchTeamByName";
export const ALL_TAG_SEARCH = "Tags/GetTagsSearchByName";
export const ALL_ANNOUNCEMENT_SEARCH =
  "Announcement/GetAnnouncementByName/GetAnnouncementByName";
export const ALL_SERVICE_SEARCH = "CustomerService/GetSearchServiceByName";
export const EXPORT_TEAM_EXCEL = "MasterUser/exportTeam-excel";
export const CONTACT_PREVIEW_DOCUMENT_DELETE = "ContactPreview/DeleteReport";
export const GET_PERTICULAR_SERVICE = "CustomerService/GetServiceDetailsById";
export const GET_PERTICULAR_TAG = "Tags/GetTagDetailsById";
export const INACTIVECONTACT_API = "Contact/GetInactiveContacts ";
export const DELETE_REATION_CONTACT = "Contact/DeleteRelation";
export const DELETE_CONTACT_PROPERTY = "Contact/DeleteContactProperty";
export const GET_SEQUENCE_DETAILS_BY_ID = "Sequence/GetSequenceByidView";

export const UPDATE_SEQUENCE_CONTACT = "Sequence/ContactSequenceStatus";
export const AUTOCOMPLETE_TAGS_API = "Tags";
export const SEQUENCE_START_API = "GraphNotification/AddSubscription"
export const SEQUENCE_ANALYZE_API = "Sequence/Sequenceanalyze";
export const SEQUENCE_ANALYZE_DETAILS_API = "Sequence/Sequenceanalyzeperformance";
export const SEQUENCE_ANALYZE_HEIGHLIGHTED_API = "Sequence/Highlyengagedcontacts";
export const POST_IMAGE_URL_CDN = "Template/AddMailerImage";