import calendarSlice from "./Calendar/calendarSlice";
import commonSlice from "./Common/commonSlice";
import contactSlice from "./Contact/contactSlice";
import AuthSlice from "./Login/AuthSlice";
import MarketingSlice from "./Marketing/MarketingSlice";
import masterSlice from "./Master/masterSlice";
import reportSlice from "./Report/reportSlice";
import SuggestSlice from "./Suggest/SuggestSlice";
import taskSlice from "./Task/taskSlice";
import TransactionSlice from "./Transaction/TransactionSlice";
import userSlice from "./User/userSlice";

const AllReducers = {
  auth: AuthSlice.reducer,
  user: userSlice.reducer,
  common: commonSlice.reducer,
  contact: contactSlice.reducer,
  task: taskSlice.reducer,
  calendar: calendarSlice.reducer,
  transaction: TransactionSlice.reducer,
  report: reportSlice.reducer,
  suggest: SuggestSlice.reducer,
  marketing: MarketingSlice.reducer,
  master: masterSlice.reducer,
};

export default AllReducers;
