import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getClosingViewDataApi,
  getListingMLSApi,
  getMetaDataMlsApi,
  getTransactionApi,
  getTransactionDropDownsApi,
  getTransactionViewApi,
  getTransactionViewApiDraft,
} from "../../Api/Services";
import { toast } from "react-toastify";

export const GetTransactionReducer = createAsyncThunk(
  "transaction/GetTransactionReducer",
  async (data) => {
    try {
      const result = await getTransactionApi(data);

      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetTransactionViewReducer = createAsyncThunk(
  "transaction/GetTransactionViewReducer",
  async (data) => {
    try {
      const result = await getTransactionViewApi(data);
      if (result?.res?.isSuccess) {
        return result.res?.data && result.res?.data[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetTransactionViewReducerDraftSection = createAsyncThunk(
  "transaction/GetTransactionViewReducerDraftSection",
  async (data) => {
    try {
      const result = await getTransactionViewApiDraft(data);
      if (result?.res?.isSuccess) {
        return result.res?.data && result.res?.data[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetTransactionClosingViewReducer = createAsyncThunk(
  "transaction/GetTransactionClosingViewReducer",
  async (data) => {
    try {
      const result = await getClosingViewDataApi(data);
      if (result?.res?.isSuccess) {
        return result.res?.data && result.res?.data[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const GetTransactionDropDownsReducer = createAsyncThunk(
  "transaction/GetTransactionDropDownsReducer",
  async () => {
    try {
      const result = await getTransactionDropDownsApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getListingMlsApiReducer = createAsyncThunk(
  "transaction/getListingMlsApiReducer",
  async () => {
    try {
      const result = await getListingMLSApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getMetaDataMlsReducer = createAsyncThunk(
  "transaction/getMetaDataMlsReducer",
  async () => {
    try {
      const result = await getMetaDataMlsApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const TransactionSlice = createSlice({
  name: "transaction",
  initialState: {
    loading: false,
    disable: false,
    AlltransactionData: [],
    ViewTransactionData: [],
    transactionDropDowns: [],
    ClosingViewData: [],
    getListingMLSData: [],
    getMetaDataMls: [],
  },

  extraReducers: (builder) => {
    builder
      .addCase(GetTransactionReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTransactionReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.AlltransactionData = action.payload;
      })
      .addCase(GetTransactionReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTransactionViewReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTransactionViewReducer.fulfilled, (state, action) => {
        state.loading = false;
        state.ViewTransactionData = action.payload;
      })
      .addCase(GetTransactionViewReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTransactionViewReducerDraftSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionViewReducerDraftSection.fulfilled,
        (state, action) => {
          state.loading = false;
          state.ViewTransactionData = action.payload;
        }
      )
      .addCase(GetTransactionViewReducerDraftSection.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        GetTransactionDropDownsReducer.fulfilled,
        (state, { payload }) => {
          state.transactionDropDowns = payload;
        }
      )
      .addCase(GetTransactionClosingViewReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionClosingViewReducer.fulfilled,
        (state, { payload }) => {
          state.loading = false;

          state.ClosingViewData = payload;
          state.ViewTransactionData = payload;
        }
      )
      .addCase(GetTransactionClosingViewReducer.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getListingMlsApiReducer.fulfilled, (state, { payload }) => {
        state.getListingMLSData = payload;
      })
      .addCase(getMetaDataMlsReducer.fulfilled, (state, { payload }) => {
        state.getMetaDataMls = payload;
      });
  },
});

export const TransactionSliceAction = TransactionSlice.actions;

export default TransactionSlice;
