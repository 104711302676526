import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Utils/Store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GlobalError from "./Components/Common/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router>
      {/* <GlobalError> */}
        <App />
      {/* </GlobalError> */}
      <ToastContainer pauseOnFocusLoss pauseOnHover closeOnClick limit={4} autoClose="1500" />
    </Router>
  </Provider>
);
