import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import {
  GEtMailerTempalte,
  GetBrochureFolder,
  GetEmailTemplate,
  GetEmailTemplateFolder,
  GetMarketingTemplateDropDown,
  GetSmsTemplate,
  GetSmsTemplateFolder,
  getBrocherTemplateApi,
  getDirectMailerTemplate,
  getPerticulaSequanceApi,
  getSequenceAnalyzeApi,
  getSequenceAnalyzeHeighlishgedApi,
  getSequenceApi,
} from "../../Api/Services";
export const getSmsTemplatereducer = createAsyncThunk(
  "report/getSmsTemplatereducer",
  async (data) => {
    try {
      const result = await GetSmsTemplate(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getBrochureFoldereducer = createAsyncThunk(
  "report/getBrochureFoldereducer",
  async (data) => {
    try {
      const result = await GetBrochureFolder(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getDirectMailerTemplatereducer = createAsyncThunk(
  "report/getDirectMailerTemplatereducer",
  async (data) => {
    try {
      const result = await GEtMailerTempalte(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getMarketingDropdwonReducer = createAsyncThunk(
  "report/getMarketingDropdwonReducer",
  async (data) => {
    try {
      const result = await GetMarketingTemplateDropDown(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getEmailTemplatereducer = createAsyncThunk(
  "report/getEmailTemplatereducer",
  async (data) => {
    try {
      const result = await GetEmailTemplate(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getSmsTemplatefolderdropdown = createAsyncThunk(
  "report/getSmsTemplatefolderdropdown",
  async (data) => {
    try {
      const result = await GetSmsTemplateFolder(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getEmailTemplatefolderdropdown = createAsyncThunk(
  "report/getEmailTemplatefolderdropdown",
  async (data) => {
    try {
      const result = await GetEmailTemplateFolder(data);

      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getSequenceReducer = createAsyncThunk(
  "report/getSequenceReducer",
  async (data) => {
    try {
      const result = await getSequenceApi(data);
      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getSuquenceAnalyzeDetailsReducer = createAsyncThunk(
  "report/getSuquenceAnalyzeDetailsReducer",
  async () => {
    try {
      const result = await getSequenceAnalyzeApi();
      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getSuquenceAnalyzeFullDetailsReducer = createAsyncThunk(
  "report/getSuquenceAnalyzeFullDetailsReducer",
  async () => {
    try {
      const result = await getSequenceAnalyzeApi(2);
      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getSuquenceHeilightedReducer = createAsyncThunk(
  "report/getSuquenceHeilightedReducer",
  async () => {
    try {
      
      const result = await getSequenceAnalyzeHeighlishgedApi(3);
      return result?.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getPerticualSequenceReducer = createAsyncThunk(
  "report/getPerticualSequenceReducer",
  async (data) => {
    try {
      const result = await getPerticulaSequanceApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getBrocherTemplateReducer = createAsyncThunk(
  "report/getBrocherTemplateReducer",
  async (data) => {
    try {
      const result = await getBrocherTemplateApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getDirectTemplateReducer = createAsyncThunk(
  "report/getDirectTemplateReducer",
  async (data) => {
    try {
      const result = await getDirectMailerTemplate(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const MarketingSlice = createSlice({
  name: "marketing",
  initialState: {
    SmsTemplateAllData: [],
    SmsfolderDropDown: [],
    EmailfolderDropDown: [],
    EmailTemplateAllData: [],
    marketingDropDownData: [],
    mailerTemplateData: [],
    brochurefolderData: [],
    sequenceanalyzeData: [],
    sequenceDetailsanalyzeData: [],
    sequenceheightlightedContactData: [],
    loading: false,
    AllCreatedSequenceData: [],
    sequenceAllData: {},
    continuewithout: false,
    getSequenceData: [],
    sequenceId: "",
    perticualSequance: [],
    getBrocherTempData: [],
    getDirectEmailTempData: [],
  },
  reducers: {
    handleUseSequence(state, { payload }) {
      state.AllCreatedSequenceData = [...state.AllCreatedSequenceData, payload];
    },

    handleSequenceId(state, { payload }) {
      state.sequenceId = payload;
    },
    handleChangeWithoutComplete(state, { payload }) {
      state.AllCreatedSequenceData = payload;
    },
    handleSaveSequenceData(state, { payload }) {
      state.sequenceAllData = payload;
    },
    handleChangeDelayData(state, { payload }) {
      state.AllCreatedSequenceData = payload;
    },
    handlePerticularSeq(state, { payload }) {
      state.AllCreatedSequenceData = payload;
    },
    handleDeleteSequences(state, { payload }) {
      state.AllCreatedSequenceData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmsTemplatereducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSmsTemplatereducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SmsTemplateAllData = payload;
      })
      .addCase(getSmsTemplatereducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSequenceReducer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSequenceReducer.fulfilled, (state, { payload }) => {
        state.getSequenceData = payload;
        state.loading = false;
      })
      .addCase(getSequenceReducer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSmsTemplatefolderdropdown.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.SmsfolderDropDown = payload;
      })
      .addCase(
        getEmailTemplatefolderdropdown.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.EmailfolderDropDown = payload;
        }
      )
      .addCase(getEmailTemplatereducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.EmailTemplateAllData = payload;
      })
      .addCase(getMarketingDropdwonReducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.marketingDropDownData = payload;
      })
      .addCase(
        getDirectMailerTemplatereducer.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.mailerTemplateData = payload;
        }
      )
      .addCase(getBrochureFoldereducer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.brochurefolderData = payload;
      })
      .addCase(getPerticualSequenceReducer.fulfilled, (state, { payload }) => {
        state.perticualSequance = payload;
      })
      .addCase(getBrocherTemplateReducer.fulfilled, (state, { payload }) => {
        state.getBrocherTempData = payload;
      })
      .addCase(getDirectTemplateReducer.fulfilled, (state, { payload }) => {
        state.getDirectEmailTempData = payload;
      })
      .addCase(
        getSuquenceAnalyzeDetailsReducer.fulfilled,
        (state, { payload }) => {
          state.sequenceanalyzeData = payload;
        }
      )
      .addCase(
        getSuquenceAnalyzeFullDetailsReducer.fulfilled,
        (state, { payload }) => {
          state.sequenceDetailsanalyzeData = payload;
        }
      )
      .addCase(getSuquenceHeilightedReducer.fulfilled, (state, { payload }) => {
        state.sequenceheightlightedContactData = payload;
      });
  },
});
export const {
  marketingAction,
  handleUseSequence,
  handleSaveSequenceData,
  handleChangeWithoutComplete,
  handleSequenceId,
  handleChangeDelayData,
  handlePerticularSeq,
  handleDeleteSequences,
} = MarketingSlice.actions;
export default MarketingSlice;
