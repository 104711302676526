import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addTaskApi,
  addTaskDropDownApi,
  getAllTaskAPi,
  getComparedToHomeApi,
  getCountByMonthTaskpi,
  getCountBySevenTaskpi,
  getPartcularTaskAPi,
  getPerticulaSequanceTaskApi,
  getTaskByDayApi,
  getTaskByWeekApi,
  taskDeleteApi,
  updateTaskStatusApi,
} from "../../Api/Services";
import { toast } from "react-toastify";
import moment from "moment";

export const AddTaskReducer = createAsyncThunk("Task/AddTask", async (data) => {
  try {
    const result = await addTaskApi(data);
  } catch (error) {
    toast.error(error.message);
  }
});
export const getPraticularTakAllData = createAsyncThunk(
  "task/getPraticularTakAllData",
  async (data) => {
    
    try {
      const result = await getPartcularTaskAPi(data.id);
      let resultdata = result?.res?.data && result?.res?.data[0];
      resultdata.taskOn = data.taskOn ?? data.eventOn;
      return result?.res?.data && result?.res?.data[0];
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getPraticularsequanceTask = createAsyncThunk(
  "task/getPraticularsequanceTask",
  async (data) => {
    try {
      const result = await getPerticulaSequanceTaskApi(data);
      return result?.res?.data && result?.res?.data[0];
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getTaskAllData = createAsyncThunk(
  "task/getTaskAllData",
  async (data) => {
    try {
      const result = await getAllTaskAPi(data);
      return result?.res;
    } catch (error) {
      toast.error(error.title);
    }
  }
);
export const getCountByMonthData = createAsyncThunk(
  "task/getCountByMonthData",
  async () => {
    try {
      const result = await getCountByMonthTaskpi();
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getComparedHomeReducer = createAsyncThunk(
  "task/getComparedHomeReducer",
  async (data) => {
    try {
      const result = await getComparedToHomeApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getCountByWeekData = createAsyncThunk(
  "task/getCountByWeekData",
  async () => {
    try {
      const result = await getCountBySevenTaskpi();
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const updateTaskStatusReducer = createAsyncThunk(
  "task/updateTaskStatusReducer",
  async (data) => {
    try {
      const result = await updateTaskStatusApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getTaskByWeekReducer = createAsyncThunk(
  "task/getTaskByWeekReducer",
  async (data) => {
    try {
      const result = await getTaskByWeekApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getTaskByDayReducer = createAsyncThunk(
  "task/getTaskByDayReducer",
  async (data, { getState, dispatch }) => {
    const state = getState();
    try {
      const result = await getTaskByDayApi(data);
      return result?.res?.data;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
// export const addTaskDropDownReducer = createAsyncThunk(
//   "task/addTaskDropDownReducer",
//   async () => {
//     try {
//       const result = await addTaskDropDownApi();
//       return result?.res;
//     } catch (error) {
//       toast.error(error.message);
//     }
//   }
// );

export const addTaskDropDownReducer = createAsyncThunk(
  "user/addTaskDropDownReducer",
  async () => {
    try {
      const result = await addTaskDropDownApi();
      return result.res;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState: {
    AllTaskData: [],
    loading: false,
    daydataloading: false,
    lloading: false,
    AllActiveTaskData: [],
    getTaskMonthData: [],
    getTaskByWeekData: [],
    getTaskByDayData: [],
    addTaskDropDownData: [],
    taskCompleted: 0,
    taskCompletedByDay: 0,
    perticularLogName: "",
    perticularAddTaskData: {},
    getPerticularsquanceTask: {},
    istodayalltaskcompleted: false,
  },
  reducers: {
    filterByShowOptions: (state, { payload }) => {
      state.getTaskByWeekData =
        state.getTaskByWeekData?.length > 0 &&
        state.getTaskByWeekData?.filter((data) => {
          return data?.status === payload;
        });
    },
    handleSetPerticularLogType: (state, { payload }) => {
      state.perticularLogName = payload;
    },
    handleISalltaskcompleted: (state, { payload }) => {
      state.istodayalltaskcompleted = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(AddTaskReducer.pending, (state) => {
        state.loading = true;
        state.disable = true;
      })
      .addCase(AddTaskReducer.fulfilled, (state) => {
        state.loading = false;
        state.disable = false;
      })
      .addCase(AddTaskReducer.rejected, (state) => {
        state.loading = false;
        state.disable = false;
      })
      .addCase(getTaskAllData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaskAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.AllTaskData = action.payload;
      })
      .addCase(getTaskAllData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCountByMonthData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountByMonthData.fulfilled, (state, action) => {
        state.loading = false;
        state.getTaskMonthData = action.payload;
      })
      .addCase(getCountByMonthData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaskByWeekReducer.fulfilled, (state, { payload }) => {
        state.getTaskByWeekData = payload;
        state.lloading = true;
        const completed = payload?.filter((data) => {
          return data?.status === 1;
        });
        const thisweekdata = payload?.filter((item) => item.flag !== "OverDue");

        if (payload?.length > 0) {
          const result = (completed?.length * 100) / thisweekdata?.length;
          state.taskCompleted = Math.round(result);
        }
      })
      .addCase(getTaskByWeekReducer.pending, (state) => {
        state.lloading = false;
      })
      .addCase(getTaskByDayReducer.pending, (state) => {
        state.daydataloading = false;
      })
      .addCase(getTaskByDayReducer.fulfilled, (state, { payload }) => {
        state.getTaskByDayData = payload;
        state.daydataloading = true;

        const completed = payload?.filter((data) => {
          return data?.status === 1;
        });

        const todaydata = payload?.filter((item) => item.flag !== "OverDue");

        if (payload?.length >= 0) {
          const result = (completed?.length * 100) / todaydata?.length;

          state.taskCompletedByDay = Math.round(result);
        }
      })
      .addCase(getTaskByDayReducer.rejected, (state) => {
        state.daydataloading = false;
      })
      .addCase(getTaskByWeekReducer.rejected, (state) => {
        state.lloading = false;
      })
      .addCase(addTaskDropDownReducer.fulfilled, (state, { payload }) => {
        state.addTaskDropDownData = payload;
      })
      .addCase(getCountByWeekData.fulfilled, (state, { payload }) => {
        state.getTaskMonthData = payload;
      })
      .addCase(getPraticularTakAllData.fulfilled, (state, action) => {
        state.perticularAddTaskData = action.payload;
      })
      .addCase(getComparedHomeReducer.fulfilled, (state, action) => {
        state.getTaskMonthData = action.payload;
      })
      .addCase(getPraticularsequanceTask.fulfilled, (state, action) => {
        state.getPerticularsquanceTask = action.payload;
      });
  },
});
export const {
  TaskAction,
  handleSetPerticularLogType,
  handleISalltaskcompleted,
} = taskSlice.actions;
export default taskSlice;
